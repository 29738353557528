import React, {
  ChangeEvent,
  ComponentState,
  FormEvent,
} from 'react'
import InputText from '../Form/InputText'
import Button from '../Button'
import ApiContext from '../Context/ApiContext'
import HtmlSelect from '../Form/HtmlSelect'
import Checkbox from '../Form/Checkbox'
import CountrySelect from '../Form/CountrySelect'
import Autosuggest, {autosuggestSuggestion} from '../Autosuggest'
import {company} from '../../types/company'

type createUserProps = {
  onClickCancel: () => void
  isInternal?: boolean
}

type createUserStates = {
  email: string
  customerNumber?: string
  salutation: string
  firstName: string
  lastName: string
  position: string
  department: string
  companyName: string
  addressName: string
  apartment: string
  state: string
  street: string
  zip: string
  city: string
  country: string
  postbox: string
  postBoxZip: string
  emailWasSent: boolean
  isValid: boolean
  privacyPolicyAggreement: boolean
  type: string
  company?: number
  companySuggestions: []
  companySuggestLoading: boolean
}

class CreateUserForm extends React.Component<createUserProps, createUserStates> {
  static defaultProps = {
    isInternal: false,
  }

  constructor(props: createUserProps) {
    super(props)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.onChangeSelect = this.onChangeSelect.bind(this)
    this.onClickSubmit = this.onClickSubmit.bind(this)
    this.onClickCancel = this.onClickCancel.bind(this)
    this.changePrivacyPolicy = this.changePrivacyPolicy.bind(this)
    this.onCompanySuggestSearch = this.onCompanySuggestSearch.bind(this)
    this.onCompanySuggestSubmit = this.onCompanySuggestSubmit.bind(this)

    this.state = {
      email: '',
      salutation: '',
      firstName: '',
      lastName: '',
      position: '',
      department: '',
      companyName: '',
      addressName: '',
      apartment: '',
      state: '',
      street: '',
      zip: '',
      city: '',
      country: '',
      postbox: '',
      postBoxZip: '',
      emailWasSent: false,
      isValid: false,
      privacyPolicyAggreement: false,
      type: 'customer', // default value
      companySuggestLoading: false,
      companySuggestions: [],
    }
  }

  onChangeInput(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault()
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.setState({
      [e.target.id]: value,
    } as ComponentState)
  }

  onChangeSelect(e: ChangeEvent<HTMLSelectElement>) {
    e.preventDefault()
    this.setState({
      [e.target.id]: e.target.value,
    } as ComponentState)
  }

  changePrivacyPolicy(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
      privacyPolicyAggreement: e.target.checked,
    } as ComponentState)
  }

  onClickSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()

    if (!this.state.privacyPolicyAggreement)
      return

    let form = {
      salutation: this.state.salutation,
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      department: this.state.department,
      position: this.state.position,
      type: this.state.type,
      addresses: [
        {
          name: this.state.addressName,
          companyName: this.state.companyName,
          additionalOne: this.state.apartment,
          additionalTwo: this.state.state,
          zip: this.state.zip,
          street: this.state.street,
          postBoxZip: this.state.postBoxZip,
          postBox: this.state.postbox,
          city: this.state.city,
          country: this.state.country,
        },
      ],
    }

    if (this.state.hasOwnProperty('customerNumber') && this.state.customerNumber) {
      form['customerNumber'] = this.state.customerNumber
    }

    if (this.state.hasOwnProperty('company') && this.state.company) {
      form['company'] = this.state.company
    }

    if (this.props.isInternal) {
      this.context.createUserInternal(form).then(() => this.onUserCreated())
    } else {
      this.context.createUserRequest(form).then(() => this.onUserCreated())
    }
  }

  onUserCreated() {
    this.setState({
      emailWasSent: true,
    })
    // @ts-ignore
    document.getElementById('popup__container').scrollTo(0, 0)
  }

  onClickCancel() {
    this.props.onClickCancel()
  }

  getSuggestionsFromCompanies(companies: company[]): autosuggestSuggestion[] {
    let suggestions: autosuggestSuggestion[] = []
    if (companies.length > 0) {
      companies.forEach(company => {
        suggestions.push({
          id: company.id,
          text: company.name,
        })
      })
    }
    return suggestions
  }

  onCompanySuggestSearch(sWord: string, hasMinCharakters: boolean) {
    if (sWord && hasMinCharakters) {
      this.setState({
        companySuggestLoading: hasMinCharakters,
        companySuggestions: [],
      }, () => {
        if (hasMinCharakters) {
          this.context.getCompanies({
            'filter[companies][search]': sWord.trim(),
            'limit': 50,
          })
            .then((result: { count: number, data: company[] }) => {
              this.setState({
                companySuggestLoading: false,
                companySuggestions: this.getSuggestionsFromCompanies(result.data),
              }, () => {
                this.setState({
                  companySuggestLoading: false,
                })
              })
            })
            .catch(() => {
              this.setState({companySuggestLoading: false})
            })
        }
      })
    }
  }

  onCompanySuggestSubmit(suggestionId?: string | number) {
    this.setState({company: parseInt(suggestionId) ?? ''})
  }

  render() {
    // linter wants to have 'rel="noopener noreferrer"' here
    // eslint-disable-next-line
    let privacyPolicyLabelText = <>I have taken note of the <a href="https://www.ptwdosimetry.com/en/footer-navigation/privacy-policy/" target="_blank">privacy policy</a>. I agree that my details and data will be collected and stored electronically to answer my inquiry</>

    return (
      !this.state.emailWasSent ? (

        <div className="container popup__form">
          <h2 className="popup__form__title">Create account</h2>
          <p className="popup__form__text">
            Please add the requested information (if available). That will help us to verify and release your registration request.<br/>
            <br/>
            Required Items *
          </p>

          <form onSubmit={this.onClickSubmit}>
            <h3 className="form__row__label">Account</h3>
            <div className="form-row">
              <InputText name="email" id="email" placeholder="E-Mail Address (only work/business address) *" type="email" onChange={this.onChangeInput} required value={this.state.email}/>
              {this.props.isInternal
                ? <Autosuggest
                  loading={this.state.companySuggestLoading}
                  onSearch={this.onCompanySuggestSearch}
                  onSubmit={this.onCompanySuggestSubmit}
                  suggestions={this.state.companySuggestions}
                  placeholder="Customer No. *"
                  minCharacters={3}
                  typingDelay={0}
                  id="company"
                  required={true}
                />
                :
                <InputText name="userNr" id="customerNumber" placeholder="Customer No. *" onChange={this.onChangeInput} required value={this.state.customerNumber}/>
              }
            </div>
            {this.props.isInternal && <div className="form-row">
              <HtmlSelect name="type" id="type" onChange={this.onChangeSelect}>
                <option value="customer">Customer</option>
                <option value="subsidiary">Subsidiary</option>
                <option value="representative">Representative</option>
              </HtmlSelect>
            </div>}

            <h3 className="form__row__label">Personal Data</h3>
            <div className="form-row">
              <HtmlSelect name="salutation" id="salutation" onChange={this.onChangeSelect}>
                <option value="">Salutation</option>
                <option value="mr">Mr.</option>
                <option value="mrs">Mrs.</option>
              </HtmlSelect>
            </div>
            <div className="form-row">
              <InputText name="fname" id="firstName" placeholder="Name *" onChange={this.onChangeInput} required value={this.state.firstName}/>
              <InputText name="lname" id="lastName" placeholder="Surname *" onChange={this.onChangeInput} required value={this.state.lastName}/>
            </div>
            <div className="form-row">
              <InputText name="person" id="position" placeholder="Position" onChange={this.onChangeInput} value={this.state.position}/>
              <InputText name="person" id="department" placeholder="Department" onChange={this.onChangeInput} value={this.state.department}/>
            </div>

            {!this.props.isInternal && <>
              <h3 className="form__row__label">Company</h3>
              <div className="form-row">
                <InputText required name="company" id="companyName" placeholder="Company Name (Company/Institute/Hospital) *" onChange={this.onChangeInput} value={this.state.companyName}/>
                <HtmlSelect name="type" id="type" onChange={this.onChangeSelect}>
                  <option value="customer">Customer</option>
                  <option value="subsidiary">Subsidiary</option>
                  <option value="representative">Representative</option>
                </HtmlSelect>
              </div>
            </>}

            <h3 className="form__row__label">Address</h3>
            <div className="form-row">
              <InputText name="address-line1" id="addressName" placeholder="Name" onChange={this.onChangeInput} value={this.state.addressName}/>
            </div>
            <div className="form-row">
              <InputText required name="street-address" id="street" placeholder="Street *" onChange={this.onChangeInput} value={this.state.street}/>
              <CountrySelect
                name="country"
                id="country"
                onChange={this.onChangeSelect}
              />
            </div>
            <div className="form-row">
              <InputText required name="postal-code" id="zip" placeholder="Zip Code *" onChange={this.onChangeInput} value={this.state.zip}/>
              <InputText required name="city" id="city" placeholder="City *" onChange={this.onChangeInput} value={this.state.city}/>
            </div>
            <div className="form-row">
              <InputText name="address-line2" id="apartment" placeholder="Additional 1 (Building, Apartment)" onChange={this.onChangeInput} value={this.state.apartment}/>
              <InputText name="address-line3" id="state" placeholder="Additional 2 (District)" onChange={this.onChangeInput} value={this.state.state}/>
            </div>
            <div className="form-row mt--small">
              <InputText name="address" id="postbox" placeholder="Post Box" onChange={this.onChangeInput} value={this.state.postbox}/>
              <InputText name="address" id="postBoxZip" placeholder="Zip Code (Post Box)" onChange={this.onChangeInput} value={this.state.postBoxZip}/>
            </div>

            <div className="mt--medium">
              <Checkbox name="privacyPolicy" defaultvalue={this.state.privacyPolicyAggreement} id="privacyPolicy" label={privacyPolicyLabelText} onChangeState={this.changePrivacyPolicy}/>
            </div>

            <div className="form-row form__bottom">
              <Button className="cancel__btn" type="button" onClick={this.onClickCancel} mode="invert">Cancel</Button>
              <Button disabled={!this.state.privacyPolicyAggreement} className="submit__btn">Create account</Button>
            </div>
          </form>
        </div>
      ) : (
        <div className="container popup__form">
          <div className="popup__form__confirmation">
            <p>
              Thank you for your registration request. It will take one business day to finish the registration process.<br/>
              <br/>
              You will receive a notification when you can use the myPTW Customer Portal.</p>
            <div className="form-row">
              <Button className="cancel__btn" type="button" onClick={this.onClickCancel} mode="invert">close</Button>
            </div>
          </div>
        </div>
      )
    )
  }
}

CreateUserForm.contextType = ApiContext

export default CreateUserForm
