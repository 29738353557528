import ApiContext from "../Context/ApiContext";
import React from "react";
import user from "../../types/user";
import {companyRelation} from "../../types/company";
import Loader from "../Loader";
import './TabCompanies.scss';
import Checkbox from "../Form/Checkbox";
import {AlertManager, withAlert} from "react-alert";

interface tabCompaniesProps {
  user: user
  editCompanyId: number | undefined,
  alert: AlertManager
}

type checkboxData = {
  id: number,
  companyId: string;
  internalId: string,
  name: string,
  allowed: boolean
}

interface tabCompaniesState {
  loading: boolean
  companyRelations: companyRelation[]
  allCompanyIds: number[],
  toggleAllActive: boolean,
  passivCompanyIds: number[],
  checkboxesData: checkboxData[]
}

class TabCompanies extends React.Component<tabCompaniesProps, tabCompaniesState> {
  constructor(props: tabCompaniesProps) {
    super(props);

    this.state = {
      loading: true,
      companyRelations: [],
      toggleAllActive: true,
      allCompanyIds: [],
      passivCompanyIds: [],
      checkboxesData: [],
    }

    this.toggleAllCompanies = this.toggleAllCompanies.bind(this);
    this.toggleCompany = this.toggleCompany.bind(this);
  }

  async componentDidMount() {
    var passiveCopmanys: companyRelation[] = [];
    var checkboxesData: checkboxData[] = [];
    var companyRealtion: companyRelation[] = [];

    await this.context.getCompanyRelationsByUser(this.props.user.id)
      .then((response: any) => {
        companyRealtion = response.data;
        this.setState({
          companyRelations: response.data
        }, () => {
          if (companyRealtion.length) {
            companyRealtion.forEach((company) => {

              // @ts-ignore
              if (!company._allowed) {
                passiveCopmanys.push(company);
                checkboxesData.push({
                  id: company.id,
                  companyId: company.relatedCompany.companyId,
                  internalId: company.relatedCompany.internalId,
                  name: company.relatedCompany.name,
                  allowed: false
                })
              } else {
                checkboxesData.push({
                  id: company.id,
                  companyId: company.relatedCompany.companyId,
                  internalId: company.relatedCompany.internalId,
                  name: company.relatedCompany.name,
                  allowed: true
                })
              }
            });
          }

          // save all passiv company ids
          let passivCompanyIds: number[] = [];
          if (passiveCopmanys.length) {
            passiveCopmanys.forEach((passivCopmany) => {
              passivCompanyIds.push(passivCopmany.id);
            });
          }

          this.setState({
            passivCompanyIds: passivCompanyIds,
            checkboxesData: checkboxesData,
            loading: false
          });
        });
      })
      .catch(() => {
        this.setState({
          loading: false
        });
      });
  }

  toggleAllCompanies() {
    // toggleAll is active and user wants to set it to passiv with click
    if (this.state.toggleAllActive) {
      // now set passiv

      let checkboxesData = this.state.checkboxesData;
      let passivCompanyIds = this.state.passivCompanyIds;
      let checkboxesBackupToggleStates: boolean[] = [];

      checkboxesData.forEach((checkbox) => {
        checkboxesBackupToggleStates.push(checkbox.allowed);
        checkbox.allowed = false;
        passivCompanyIds.push(checkbox.id);
      });

      this.setState({
        checkboxesData: checkboxesData,
        toggleAllActive: false,
      })

      this.context.deleteAllCompanyRelations(this.props.user.id).then(() => {
        this.setState({
          passivCompanyIds: passivCompanyIds,
        })
      }).catch(() => {
        this.props.alert.error("Failed to deactivate all companies. Resetet all toggles");
        let checkboxesreset = this.state.checkboxesData;
        checkboxesreset.forEach((checkbox, index) => {
          checkbox.allowed = checkboxesBackupToggleStates[index];
        })

        this.setState({
          toggleAllActive: true,
          checkboxesData: checkboxesreset
        });
      });
    } else {
      // get all companys to send them and trun toggleAll to positiv
      let allCompanyIds: number[] = [];
      let checkboxesData = this.state.checkboxesData;
      let checkboxesBackupToggleStates: boolean[] = [];

      checkboxesData.forEach((checkbox, index) => {
        checkboxesBackupToggleStates.push(checkbox.allowed);
        allCompanyIds.push(checkbox.id);
        checkboxesData[index].allowed = true;
      })

      this.setState({
        checkboxesData: checkboxesData,
        toggleAllActive: true,
      })

      this.context.activateCompanyRealtionByUser(this.props.user.id, {}, {
        companyRelations: allCompanyIds
      })
        .then(() => {
          this.setState({
            passivCompanyIds: [],
          })
        }).catch(() => {
        this.props.alert.error("Failed to activate all companies. Resetet all toggles");
        let checkboxesreset = this.state.checkboxesData;

        checkboxesreset.forEach((checkbox, index) => {
          checkbox.allowed = checkboxesBackupToggleStates[index];
        })

        this.setState({
          toggleAllActive: false,
          checkboxesData: checkboxesreset
        });
      });
    }
  }

  toggleCompany(companyId: number, isActive: boolean) {
    if (isActive) {
      // now set to passiv
      let checkboxDataIndex = this.state.checkboxesData.findIndex(x => x.id === companyId);
      let checkboxData = this.state.checkboxesData;
      checkboxData[checkboxDataIndex].allowed = false;
      this.setState({
        checkboxesData: checkboxData
      });

      this.context.deleteCompanyRelationByUser(this.props.user.id, companyId)
        .then(() => {
          let passivCompanyIds = this.state.passivCompanyIds;
          passivCompanyIds.push(companyId);

          this.setState({
            passivCompanyIds: passivCompanyIds,
          })
        })
        .catch(() => {
          this.props.alert.error("Failed to deactivate company: " + checkboxData[checkboxDataIndex].name + '. Toggle state was resetet');
          checkboxData[checkboxDataIndex].allowed = true;

          this.setState({
            checkboxesData: checkboxData
          });
        });
    } else {
      //now set to active
      let checkboxDataIndex = this.state.checkboxesData.findIndex(x => x.id === companyId);
      let checkboxData = this.state.checkboxesData;
      checkboxData[checkboxDataIndex].allowed = true;

      this.setState({
        checkboxesData: checkboxData
      });

      this.context.activateCompanyRealtionByUser(this.props.user.id, {}, {
        companyRelations: [companyId]
      })
        .then(() => {
          let passivCompanyIds = this.state.passivCompanyIds;
          let index = passivCompanyIds.indexOf(companyId);
          passivCompanyIds.slice(index, 1);

          this.setState({
            passivCompanyIds: passivCompanyIds,
          })
        }).catch(() => {
        this.props.alert.error("Failed to activate company: " + checkboxData[checkboxDataIndex].name + '. Toggle state was resetet');
        checkboxData[checkboxDataIndex].allowed = false;

        this.setState({
          checkboxesData: checkboxData
        });
      });
    }
  }

  render() {
    return (
      <div className="user-companies">
        {this.state.loading
          ? <Loader mode="inverted"/>
          : (this.state.companyRelations && this.state.companyRelations.length)
            ? <div className="table-wrapper">
              <table className="table companytable">
                <thead>
                <tr key="table-header-row">
                  <td>PTW Company ID</td>
                  <td>Internal Company ID</td>
                  <td>Company Name</td>
                  <td>
                    <Checkbox
                      mode="switch"
                      defaultvalue={true}
                      onChangeState={this.toggleAllCompanies}
                      addFormGroup={false}
                      className={this.state.toggleAllActive ? 'active' : 'passiv'}
                      id="all-companies-switch"
                    />
                  </td>
                </tr>
                </thead>
                <tbody>
                {this.state.checkboxesData.map((checkboxData, index) => {
                  return (
                    <tr key={'company-' + index}>
                      <td>{checkboxData.companyId}</td>
                      <td>{checkboxData.internalId}</td>
                      <td>{checkboxData.name}</td>
                      <td>
                        <Checkbox
                          mode="switch"
                          defaultvalue={checkboxData.allowed}
                          onChangeState={() => this.toggleCompany(checkboxData.id, checkboxData.allowed)}
                          addFormGroup={false}
                          className={checkboxData.allowed ? 'active' : 'passiv'}
                          id={'company-switch-' + checkboxData.id}
                        />
                      </td>
                    </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
            : <div className="no-results typo-lg text-center">No companies found</div>
        }
      </div>
    );
  }
}

TabCompanies.contextType = ApiContext;
export default withAlert<tabCompaniesProps>()(TabCompanies);