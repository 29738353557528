import React, {
  ChangeEvent
} from 'react';
import Loader from "./Loader";
import faq from "../types/faq";
import Accordion from "./Accordion";
import InputText from "./Form/InputText";
import faqCategory from "../types/faqCategory";
import ApiContext from "./Context/ApiContext";
import LoadingIndicator from "./LoadingIndicator";
import {ValueType} from "react-select/src/types";
import {OptionType} from "./Select/SelectTypes";
import Select from "./Select/Select";

type faqCategoryStates = {
  faq: faq[],
  loaded: boolean,
  loadedMore: boolean,
  faqCount: number,
  faqLength: number,
  limit: number,
  offset: number,
  search: string,
  timeout: any,
  filtered: boolean,
  typingDoneDelay: number,
  faqCategories: faqCategory[]
  faqCategoriesFilterOptions: OptionType[]
  faqCategoriesFilterOptionDefault: OptionType
  faqCategoriesFilterValue: OptionType
}

type faqCategoryProps = {
  defaultCategory: faqCategory
}

const CATEGORY_FILTER_DEFAULT_OPTION_LABEL = 'Category';

class FaqCategory extends React.Component<faqCategoryProps, faqCategoryStates> {
  constructor(props: faqCategoryProps) {
    super(props);

    let defaultOption = {
      value: props.defaultCategory.title,
      label: CATEGORY_FILTER_DEFAULT_OPTION_LABEL
    };

    this.state = {
      faq: [],
      loaded: false,
      loadedMore: true,
      faqCount: 10, // has to be equal to limit, is set @componentDidMount
      limit: 10,
      faqLength: 10,
      offset: 0,
      search: '',
      timeout: '',
      filtered: false,
      typingDoneDelay: 600,
      faqCategories: [],
      faqCategoriesFilterOptions: [defaultOption],
      faqCategoriesFilterValue: defaultOption,
      faqCategoriesFilterOptionDefault: defaultOption
    };

    this.handleFaqRequest = this.handleFaqRequest.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.onClickLoadMore = this.onClickLoadMore.bind(this);
  }

  componentDidMount(): void {
    this.setState({
      faqCount: this.state.limit
    });
    this.getCategories();
    this.handleFaqRequest();
  }

  handleFaqRequest(resetOffset_?: boolean) {
    const params = {
      limit: resetOffset_ ? 10 : this.state.limit,
      offset: resetOffset_ ? 0 : this.state.offset,
      'filter[faq][question]': this.state.search.trim(),
      'filter[faq][answer]': this.state.search.trim(),
      'sort[id]': 'DESC',
      'filter[faq][categories.title]': this.state.faqCategoriesFilterValue.value
    };

    this.context.getFaq(params).then((devicesObj: { count: number, data: faq[] }) => {
      if (resetOffset_) {
        this.setState({
          faq: devicesObj.data,
          faqCount: devicesObj.count,
          offset: 0,
          loaded: true,
          loadedMore: true,
        })
      } else {
        this.setState({
          faq: devicesObj ? this.state.faq.concat(devicesObj.data) : [],
          faqCount: devicesObj.count,
          loaded: true,
          loadedMore: true,
        })
      }
    }, () => {
      this.setState({
        loaded: true,
        loadedMore: true
      })
    });
  }

  getCategories() {
    const params = {
      'sort[title]': 'ASC'
    };
    let faqCategoriesFilterOptionsObjects: OptionType[] = [{
      value: this.props.defaultCategory.title,
      label: CATEGORY_FILTER_DEFAULT_OPTION_LABEL
    }];

    this.context.getSubcategories(this.props.defaultCategory.id, params).then((faqCategoriesObj: { count: number, data: faqCategory[] }) => {
      this.setState({
        faqCategories: faqCategoriesObj.data
      }, () => {
        this.state.faqCategories.forEach((category) => {
          faqCategoriesFilterOptionsObjects.push({value: category.title, label: category.title})
        })

        this.setState({
          faqCategoriesFilterOptions: faqCategoriesFilterOptionsObjects
        })
      })
    });
  }

  setFilter(triggerRequest?: boolean) {
    this.setState({
      faq: [],
      filtered: false,
      search: this.state.search.trim(),
      loaded: false
    }, () => {
      // callback after asynchron state changes needed for select filter changes
      if (triggerRequest) {
        this.handleFaqRequest(true);
      }
    });
  }

  onClickLoadMore() {
    this.setState({
      loadedMore: false,
      offset: this.state.offset + this.state.limit,
      faqCount: this.state.faqCount + this.state.limit
    }, this.handleFaqRequest);
  }

  onChangeInput(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    this.setState({
      search: e.target.value
    }, () => {
      clearTimeout(this.state.timeout);
      this.setState({
        timeout: setTimeout(() => {
          this.setFilter(true);
        }, this.state.typingDoneDelay)
      });
    });
  }

  onChangeSelect(value: ValueType<OptionType, false> | null): void {
    this.setState({
      faqCategoriesFilterValue: value ?? this.state.faqCategoriesFilterOptionDefault
    }, () => {
      this.setFilter(true);
    });
  }

  render() {
    return (
      <div id="faqoverview">
        <div className="contentbox">
          <div className="faq__filter__wrapper row small-gutter">
            <div className="col-12 col-sm-6 col-lg-4">
              <InputText
                placeholder="Search"
                mode="highlighted"
                id="search"
                onChange={this.onChangeInput}
                value={this.state.search}
                showReset={!!(this.state.search) && this.state.search !== ''}
                onClickReset={() => {
                  this.setState({search: ''}, () => {
                    this.setFilter(true);
                  });
                }}
              />
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              {this.state.faqCategories.length ? (
                <Select
                  placeholder="Search"
                  id="category"
                  menuIsOpen={true}
                  options={this.state.faqCategoriesFilterOptions}
                  // @ts-ignore
                  onChange={this.onChangeSelect}
                  selectLabel={this.state.faqCategoriesFilterOptions[0].label}
                  value={this.state.faqCategoriesFilterValue}
                  showReset={this.state.faqCategoriesFilterValue.value !== this.state.faqCategoriesFilterOptionDefault.value}
                  onClickReset={() => {
                    this.setState({faqCategoriesFilterValue: this.state.faqCategoriesFilterOptionDefault}, () => {
                      this.setFilter(true);
                    });
                  }}
                />
              ) : (
                <></>
              )
              }
            </div>
          </div>
          {this.state.loaded ? (
            <>
              {this.state.faq ? (
                <>
                  {this.state.faq.map((faq, i_) => {
                    return (
                      <Accordion key={i_} faq={faq} title={faq.question} mode="faq">
                        <div dangerouslySetInnerHTML={{__html: faq.answer}} className="rte-content faq__text"></div>
                      </Accordion>
                    )
                  })}
                </>
              ) : (
                <h3>No FAQs found.</h3>
              )}
            </>
          ) : (
            <Loader mode="overview"/>
          )
          }
          {this.state.faq.length > 0 && (
            this.state.faqCount > this.state.offset + this.state.limit &&
            <div className="loading__indicator__wrapper">
              <div onClick={this.onClickLoadMore.bind(this)}>
                <LoadingIndicator loading={!this.state.loadedMore}
                                  text="Show more"/>
              </div>
            </div>
          )
          }
        </div>
      </div>
    )
  }
}

FaqCategory.contextType = ApiContext;

export default FaqCategory;
