import React from "react";
import Header from './Header';
import Navigation from './Navigation';
import Footer from './Footer';
import MyAccount from "./User/MyAccount";
import MyTeam from "./User/MyTeam";
import UiKit from './UiKit/UiKit';
import AuthRoute from './Route/AuthRoute';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import NotFound404 from "./ErrorPages/NotFound404";
import DeviceDetail from "./Device/DeviceDetail";
import DeviceOverview from "./Device/DeviceOverview";
import AuthProvider from "./Provider/AuthProvider";
import AuthContext from "./Context/AuthContext";
import Dashboard from "./Dashboard";
import templateMode from "../types/templateMode";
import ComponentDetail from "./Device/ComponentDetail";
import ApiProvider from "./Provider/ApiProvider";
import {AlertManager} from "react-alert";
import UserManagement from "./User/UserManagement";
import AuthorizeEndpoint from "./AuthorizeEndpoint";
import FaqOverview from "./FaqOverview";
import CompanyOverview from "./Company/CompanyOverview";
import DeviceAddressOverview from "./Device/DeviceAddressOverview";
import CompanyAdministration from "./Company/CompanyAdministration";
import PageService, {
  PAGE_COMPANY_ADMINISTRATION,
  PAGE_COMPANY_OVERVIEW,
  PAGE_PRODUCT_ADRESSES,
  PAGE_MY_PRODUCTS,
  PAGE_SERVICE_CENTER,
  PAGE_UI,
  PAGE_HOME,
  PAGE_MY_ACCOUNT,
  PAGE_MY_TEAM,
  PAGE_USER_MANAGEMENT,
  PAGE_AUTHORIZE
} from "../services/PageService";
import Loader from "./Loader";

type masterProps = {
  mode: templateMode,
  alertManager: AlertManager
}

type masterStates = {
  mode: string
}

class Master extends React.Component<masterProps, masterStates> {
  constructor(props: masterProps) {
    super(props);
    this.state = {
      mode: 'default'
    };

    this.setMode = this.setMode.bind(this);
  }

  setMode(value: templateMode) {
    if (this.state.mode !== value) {
      this.setState({
        mode: value
      });
    }
  };

  render() {
    let pageConfigs = PageService.pagesConfigurations;
    return (
      <div id="master" className={'mode--' + this.state.mode}>
        <ApiProvider alertManager={this.props.alertManager}>
          <Router>
            <AuthProvider>
              <AuthContext.Consumer>
                {({isAuth, user, authFinished}) => (
                  authFinished
                    ? <>
                      {isAuth &&
                        <Header user={user}/>
                      }
                      {this.state.mode !== 'no_navigation' &&
                        <div className="container">
                          <Navigation user={user}/>
                        </div>
                      }
                      <main>
                        <Switch>
                          {/* Products */}
                          <AuthRoute path={pageConfigs[PAGE_MY_PRODUCTS].path + '/:deviceId/:componentId'} pageId={PAGE_MY_PRODUCTS} setMode={this.setMode} isAuth={isAuth} user={user} component={ComponentDetail}/>
                          <AuthRoute path={pageConfigs[PAGE_MY_PRODUCTS].path + '/:deviceId'} pageId={PAGE_MY_PRODUCTS} setMode={this.setMode} isAuth={isAuth} user={user} component={DeviceDetail}/>
                          <AuthRoute path={pageConfigs[PAGE_MY_PRODUCTS].path} pageId={PAGE_MY_PRODUCTS} setMode={this.setMode} isAuth={isAuth} user={user} component={DeviceOverview}/>

                          {/* Product Addresses */}
                          <AuthRoute path={pageConfigs[PAGE_PRODUCT_ADRESSES].path + '/:deviceId/:componentId'} pageId={PAGE_PRODUCT_ADRESSES} setMode={this.setMode} isAuth={isAuth} user={user} component={ComponentDetail}/>
                          <AuthRoute path={pageConfigs[PAGE_PRODUCT_ADRESSES].path + '/:deviceId'} pageId={PAGE_PRODUCT_ADRESSES} setMode={this.setMode} user={user} isAuth={isAuth} component={DeviceDetail}/>
                          <AuthRoute path={pageConfigs[PAGE_PRODUCT_ADRESSES].path} pageId={PAGE_PRODUCT_ADRESSES} setMode={this.setMode} isAuth={isAuth} user={user} component={DeviceAddressOverview}/>

                          {/* User */}
                          <AuthRoute path={pageConfigs[PAGE_MY_TEAM].path} pageId={PAGE_MY_TEAM} setMode={this.setMode} user={user} mode="no_navigation" isAuth={isAuth} component={MyTeam}/>
                          <AuthRoute path={pageConfigs[PAGE_USER_MANAGEMENT].path} pageId={PAGE_USER_MANAGEMENT} setMode={this.setMode} isAuth={isAuth} user={user} component={UserManagement}/>
                          <AuthRoute path={pageConfigs[PAGE_MY_ACCOUNT].path} pageId={PAGE_MY_ACCOUNT} setMode={this.setMode} isAuth={isAuth} user={user} component={MyAccount}/>

                          {/* Comapny */}
                          <AuthRoute path={pageConfigs[PAGE_COMPANY_OVERVIEW].path} pageId={PAGE_COMPANY_OVERVIEW} setMode={this.setMode} isAuth={isAuth} user={user} mode="no_navigation" component={CompanyOverview}/>
                          <AuthRoute path={pageConfigs[PAGE_COMPANY_ADMINISTRATION].path} pageId={PAGE_COMPANY_ADMINISTRATION} setMode={this.setMode} isAuth={isAuth} user={user} mode="no_navigation" component={CompanyAdministration}/>

                          {/* MISC */}
                          <AuthRoute path={pageConfigs[PAGE_SERVICE_CENTER].path} pageId={PAGE_SERVICE_CENTER} setMode={this.setMode} isAuth={isAuth} user={user} component={FaqOverview}/>
                          <AuthRoute path={pageConfigs[PAGE_UI].path} pageId={PAGE_UI} setMode={this.setMode} isAuth={isAuth} user={user} component={UiKit}/>
                          <Route path={pageConfigs[PAGE_AUTHORIZE].path} pageId={PAGE_AUTHORIZE} component={AuthorizeEndpoint}/>
                          <AuthRoute path={pageConfigs[PAGE_HOME].path} pageId={PAGE_HOME} setMode={this.setMode} isAuth={isAuth} user={user} exact component={Dashboard}/>

                          {/* Not Found */}
                          <Route component={NotFound404}/>
                        </Switch>
                      </main>
                    </>
                    : <Loader wrapperClassName="loader--page"/>
                )}
              </AuthContext.Consumer>
            </AuthProvider>
            <Footer/>
          </Router>
        </ApiProvider>
      </div>
    )
  }
}

export default Master;
