import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from "./components/App";
import {
  Provider as AlertProvider,
} from 'react-alert'
import AlertTemplate from "./components/Alert/template";
import alertDefaultOptions from "./components/Alert/defaultOptions";
import axios from "axios";

axios.defaults.withCredentials = true

const Root = () => (
  <AlertProvider template={AlertTemplate} {...alertDefaultOptions}>
    <App />
  </AlertProvider>
);
ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
