import React, {
  ChangeEvent,
  ComponentState,
  FormEvent,
} from 'react'
import InputText from '../Form/InputText'
import Button from '../Button'
import person from '../../types/Person'
import user from '../../types/user'
import Loader from '../Loader'
import ApiContext from '../Context/ApiContext'
import {userRole, userRoleEntry} from '../../types/userRole'
import {apiResultCountable} from '../Provider/ApiProvider'

type createAccountProps = {
  onClickCancel: () => void
  user?: user,
  handleTeamRequest: (initial: boolean) => void
}

type createAccountStates = {
  email: string,
  firstName: string,
  lastName: string,
  accountCreated: boolean,
  person?: person,
  isValid: boolean,
  personId: string,
  loaded: boolean,
  role: userRole,
  selectableRoles: userRoleEntry[]
  selectableRolesLoading: boolean
}

class CreateAccount extends React.Component<createAccountProps, createAccountStates> {
  constructor(props: createAccountProps) {
    super(props)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.onClickSubmit = this.onClickSubmit.bind(this)
    this.onClickCancel = this.onClickCancel.bind(this)
    this.onClickClose = this.onClickClose.bind(this)
    this.onChangeSelect = this.onChangeSelect.bind(this)
    this.onChangeRole = this.onChangeRole.bind(this)

    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      accountCreated: false,
      personId: '',
      isValid: false,
      loaded: true,
      role: '',
      selectableRoles: [],
      selectableRolesLoading: false,
    }
  }

  componentDidMount() {
    this.setState({
      selectableRolesLoading: true,
    }, () => {
      if (this.props.user) {
        this.context.getUserRoles(this.props.user.id)
          .then((response: apiResultCountable<userRoleEntry>) => {
            this.setState({
              selectableRoles: response.data,
              selectableRolesLoading: false,
            })
          })
          .catch(() => {
            this.setState({
              selectableRolesLoading: false,
            })
          })

      }
    })
  }

  onChangeInput(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault()
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    this.setState({
      [e.target.id]: value,
    } as ComponentState)
  }

  onClickSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const accountInfo = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
    }

    if (this.state.person) {
      Object.assign(accountInfo, {
        person: this.state.person,
      })
    }

    if (this.state.role) {
      Object.assign(accountInfo, {
        roles: [this.state.role],
      })
    }

    this.setState({
      loaded: false,
    })

    if (this.state.personId !== '') {
      // creates a user linked to a person
      this.context.createUserForEmployee(this.state.personId, accountInfo).then(() => {
        this.setState({
          accountCreated: true,
          loaded: true,
        }, () => {
          this.setState({
            loaded: true,
          })
        })
      }).catch(() => {
        this.setState({
          loaded: true,
        })
        // @ts-ignore
        document.getElementById('popup__container').scrollTo(0, 0)
      })
    } else {
      // creates a user without linked person
      this.context.createUserForCompany(this.props.user?.company?.id, accountInfo).then(() => {
        this.setState({
          accountCreated: true,
          loaded: true,
        }, () => {
          this.setState({
            loaded: true,
          })
        })
      }).catch(() => {
        this.setState({
          loaded: true,
        })
        // @ts-ignore
        document.getElementById('popup__container').scrollTo(0, 0)
      })
    }
  }

  onChangeSelect(e: ChangeEvent<HTMLSelectElement>) {
    e.preventDefault()
    this.setState({
      personId: e.target.value,
    })
  }

  onChangeRole(e: ChangeEvent<HTMLSelectElement>) {
    e.preventDefault()
    this.setState({
      role: e.target.value,
    })
  }

  onClickCancel() {
    this.props.onClickCancel()
  }

  onClickClose() {
    this.props.handleTeamRequest(true)
    this.props.onClickCancel()
  }

  render() {
    return (
      !this.state.loaded ? (
        <Loader className="success" mode="inverted"/>
      ) : (
        !this.state.accountCreated ? (
          <div className="container popup__form">
            <h2 className="popup__form__title">Create new account</h2>
            <form onSubmit={this.onClickSubmit}>
              <div className="form-row">
                <InputText name="user" id="firstName" placeholder="Firstname*" onChange={this.onChangeInput} required value={this.state.firstName}/>
                <InputText name="user" id="lastName" placeholder="Lastname*" onChange={this.onChangeInput} required value={this.state.lastName}/>
              </div>

              <div className="form-row">
                <InputText name="user" id="email" placeholder="E-Mail Adress*" type="email" onChange={this.onChangeInput} required value={this.state.email}/>
                <div className="form-group">
                  {this.state.selectableRolesLoading
                    ? <Loader mode="xs_inverted"/>
                    : <select
                      className="form-control"
                      id="role"
                      name="role"
                      onChange={this.onChangeRole}
                      required={true}
                    >
                      <option value="" key="-1">Role*</option>
                      {this.state.selectableRoles.map((role, index) => {
                        return <option value={role.key} key={index}>{role.name}</option>
                      })}
                    </select>
                  }
                </div>
              </div>

              <div className="form-row form__bottom">
                <Button className="cancel__btn" type="button" onClick={this.onClickCancel} mode="invert">Cancel</Button>
                <Button className="submit__btn">Create account</Button>
              </div>

            </form>
          </div>
        ) : (
          <div className="container popup__form">
            <div className="popup__form__confirmation">
              <p>
                The Account for {this.state.firstName} {this.state.lastName} was created successfully.
              </p>
              <div className="form-row">
                <Button className="cancel__btn" type="button" onClick={this.onClickClose} mode="invert">close</Button>
              </div>
            </div>
          </div>
        )
      )
    )
  }
}

CreateAccount.contextType = ApiContext

export default CreateAccount
