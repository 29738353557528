import React from 'react'
import axios, {AxiosResponse} from 'axios';
import {API_ENDPOINTS, MAX_ENTRIES_PER_REQUEST} from "../../static/apiEndpoints";
import device from "../../types/device";
import user from "../../types/user";
import deviceHistory from "../../types/deviceHistory";
import news from "../../types/news";
import deviceDownload from "../../types/deviceDownload";
import faq from "../../types/faq";
import faqCategory from "../../types/faqCategory";
import person from "../../types/Person";
import {AlertManager} from "react-alert";
import ApiContext from "../Context/ApiContext";
import calibration from "../../types/calibration";
import detailedErrorResponse from "../../types/detailedErrorResponse";
import {company, companyRelation, companyUpdateData} from "../../types/company";
import EnvironmentService from "../../services/EnvironmentService";
import errorMessagesMapping from "../../types/errorMessages/errorMessagesMapping";
import errorMessagesValidationLabelsMapping from "../../types/errorMessages/errorMessagesValidationLabelsMapping";
import {
  errorMessagesLogin,
  ERROR_MESSAGES_BY_ENDPOINT,
  ERROR_MESSAGE_LABELS_BY_ENDPOINT
} from "../../static/errorMessages";
import {LOCALSTORAGE_KEY_ERROR_NEXT_LOAD, LOCALSTORAGE_KEY_ERROR_NEXT_LOAD_DATE} from "../../types/localStorage";
import address from "../../types/address";
import {userRoleEntry} from "../../types/userRole";
import {API_URL_ID_PLACEHOLDER} from '../../static/apiEndpoints';
import {getValueByRegexKeyFromObject} from "../../util/object";
import {stringKeyObject} from "../../types/common";
import calibrationCertificate from "../../types/calibrationCertificate";

// global states
type apiStates = {
  user: user,
  isAuth: boolean
}
type apiProviderOptions = {
  alertManager: AlertManager
};

export type apiResultCountable<resultType = object> = {
  count: number
  data: resultType[]
}

class ApiProvider extends React.Component<apiProviderOptions, apiStates> {
  // regex expressions can be used here for IDs in URL
  static customErrorHandlingEndpoints: string[] = [
    API_ENDPOINTS.getUser,
    API_ENDPOINTS.activateUserFormCompanyRelation.replace(API_URL_ID_PLACEHOLDER, '(.*?)') + '(.*?)',
    API_ENDPOINTS.deleteUserFromCompanyRelation.replace(API_URL_ID_PLACEHOLDER, '(.*?)') + '(.*?)',
    API_ENDPOINTS.deleteAllCompanyRelations.replace(API_URL_ID_PLACEHOLDER, '(.*?)') + '(.*?)',
    API_ENDPOINTS.activateCompanyRealtionByUser.replace(API_URL_ID_PLACEHOLDER, '(.*?)'),
    API_ENDPOINTS.deleteAllCompanyRelations.replace(API_URL_ID_PLACEHOLDER, '(.*?)') + '(.*?)',
  ];
  static delayedErrorEndpoints: string[] = [
    API_ENDPOINTS.authorize,
  ];

  // constructor
  constructor(props: apiProviderOptions) {
    super(props);

    this.authorize = this.authorize.bind(this);
    this.createCalibration = this.createCalibration.bind(this);
    this.createUserForCompany = this.createUserForCompany.bind(this);
    this.createUserForEmployee = this.createUserForEmployee.bind(this);
    this.createUserRequest = this.createUserRequest.bind(this);
    this.createUserInternal = this.createUserInternal.bind(this);
    this.getAllCompanyContacts = this.getAllCompanyContacts.bind(this);
    this.getAllUsersForCompany = this.getAllUsersForCompany.bind(this);
    this.getCalibration = this.getCalibration.bind(this);
    this.getCompanies = this.getCompanies.bind(this);
    this.getCompaniesByUser = this.getCompaniesByUser.bind(this);
    this.getCompanyRelatedCompanies = this.getCompanyRelatedCompanies.bind(this);
    this.updateCompany = this.updateCompany.bind(this);
    this.getAllowedCompanyRelations = this.getAllowedCompanyRelations.bind(this);
    this.getCompanyRelationsByUser = this.getCompanyRelationsByUser.bind(this);
    this.deleteAllCompanyRelations = this.deleteAllCompanyRelations.bind(this);
    this.deleteCompanyRelationByUser = this.deleteCompanyRelationByUser.bind(this);
    this.deleteAllUserFromCompanyRelation = this.deleteAllUserFromCompanyRelation.bind(this)
    this.activateUserFormCompanyRelation = this.activateUserFormCompanyRelation.bind(this);
    this.activateCompanyRealtionByUser = this.activateCompanyRealtionByUser.bind(this);
    this.getCompanyRelations = this.getCompanyRelations.bind(this);
    this.addCompanyRelation = this.addCompanyRelation.bind(this);
    this.deleteCompanyRelation = this.deleteCompanyRelation.bind(this);
    this.updateCompanyRelation = this.updateCompanyRelation.bind(this);
    this.getComponents = this.getComponents.bind(this);
    this.getDevice = this.getDevice.bind(this);
    this.getDevices = this.getDevices.bind(this);
    this.getAddressRelatedDevices = this.getAddressRelatedDevices.bind(this);
    this.getDeviceAddressesFromCompany = this.getDeviceAddressesFromCompany.bind(this);
    this.getAddressesByCompany = this.getAddressesByCompany.bind(this);
    this.getDownloads = this.getDownloads.bind(this);
    this.getEmployees = this.getEmployees.bind(this);
    this.exportDevicesExcel = this.exportDevicesExcel.bind(this);
    this.getErrorUrl = this.getErrorUrl.bind(this);
    this.getFaq = this.getFaq.bind(this);
    this.getFaqRootCategories = this.getFaqRootCategories.bind(this);
    this.getHistory = this.getHistory.bind(this);
    this.getLoginUrl = this.getLoginUrl.bind(this);
    this.getNews = this.getNews.bind(this);
    this.getSubcategories = this.getSubcategories.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.getUser = this.getUser.bind(this);
    this.getUserRoles = this.getUserRoles.bind(this);
    this.handleError = this.handleError.bind(this);
    this.loginTest = this.loginTest.bind(this);
    this.logout = this.logout.bind(this);
    this.logoutTest = this.logoutTest.bind(this);
    this.sendMessageToContact = this.sendMessageToContact.bind(this);
    this.updateCalibration = this.updateCalibration.bind(this);
    this.updateUser = this.updateUser.bind(this);

    // global error handling
    axios.interceptors.response.use((response) => response, (error) => {
      if (typeof error === 'object' && error.hasOwnProperty('response') && error.response.config.url) {
        let canHandleError = true;
        if (ApiProvider.customErrorHandlingEndpoints.includes(error.response.config.url)) {
          canHandleError = false;
        } else {
          for (const endpointToCheck of ApiProvider.customErrorHandlingEndpoints) {
            if (error.response.config.url.match(endpointToCheck)) {
              canHandleError = false;
              break;
            }
          }
        }

        if (error.response.config.url.startsWith(API_ENDPOINTS.authorize)) {
          localStorage.setItem(LOCALSTORAGE_KEY_ERROR_NEXT_LOAD, error.response.data.error.message);
          localStorage.setItem(LOCALSTORAGE_KEY_ERROR_NEXT_LOAD_DATE, new Date().toString());
          if (error.response.status === 401)
            this.logout();
        }

        if (canHandleError) {
          if (ApiProvider.delayedErrorEndpoints.includes(error.response.config.url)) {
            setTimeout(() => {
              this.handleError(error.response.data, error.response.config.url);
            }, 1000);
          } else {
            this.handleError(error.response.data, error.response.config.url);
          }
        }
      }
    });
  }

  // helper
  handleError(response: detailedErrorResponse, url?: string, detailedErrorMessages?: errorMessagesMapping, detailedErrorMessagesLabelsMapping?: errorMessagesValidationLabelsMapping) {
    const error = response.error;

    if (EnvironmentService.isDevelopment())
      console.error('ApiProvider::handleError', JSON.parse(JSON.stringify(response)));
    if (typeof error === 'undefined')
      return;

    if (url) {
      if (!detailedErrorMessages)
        detailedErrorMessages = getValueByRegexKeyFromObject<errorMessagesMapping>(url, ERROR_MESSAGES_BY_ENDPOINT, API_URL_ID_PLACEHOLDER);
      if (!detailedErrorMessagesLabelsMapping)
        detailedErrorMessagesLabelsMapping = getValueByRegexKeyFromObject<errorMessagesValidationLabelsMapping>(url, ERROR_MESSAGE_LABELS_BY_ENDPOINT, API_URL_ID_PLACEHOLDER);
    }

    let errorMessageTitle = error.message;
    let errorMessages = [];

    if (error.details) {
      for (let errorDetail in error.details) {
        let label = errorDetail;
        let errorMessage = error.details[errorDetail].error;

        if (detailedErrorMessagesLabelsMapping && detailedErrorMessagesLabelsMapping[errorDetail])
          label = detailedErrorMessagesLabelsMapping[errorDetail];
        if (detailedErrorMessages && detailedErrorMessages[error.statusCode][errorDetail])
          errorMessage = detailedErrorMessages[error.statusCode][errorDetail];

        errorMessages.push(label + ': ' + errorMessage);
      }
    } else {
      if (detailedErrorMessages && detailedErrorMessages[error.statusCode]['default']) {
        errorMessageTitle = detailedErrorMessages[error.statusCode]['default']
      }
    }

    let message = <>
      {errorMessageTitle}<br/>
      {errorMessages.map((message_) => {
        return <>
          {message_}<br/>
        </>
      })}
    </>;

    switch (error.statusCode) {
      case 404:
        this.props.alertManager.error(message);
        break;
      case 400:
        this.props.alertManager.info(message);
        break;
      case 403:
        if (EnvironmentService.isDevelopment())
          this.props.alertManager.info(message);
        else
          this.props.alertManager.info('403: Access denied');
        break;
      default:
        this.props.alertManager.error(message);
    }
  }

  setLimit(params: stringKeyObject = {}): stringKeyObject {
    if (!params.hasOwnProperty('limit')) {
      params = {
        ...params,
        limit: MAX_ENTRIES_PER_REQUEST
      }
    }
    return params;
  }

  createUserRequest(form: string) {
    return new Promise<{}>((resolve, reject) => {
      axios.post(API_ENDPOINTS.createUser, form)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  createUserInternal(form: string) {
    return new Promise<{}>((resolve, reject) => {
      axios.post(API_ENDPOINTS.createUserInternal, form)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  updateUser(id_: number, params: stringKeyObject) {
    let endpoint = API_ENDPOINTS.updateUser.replace(API_URL_ID_PLACEHOLDER, id_.toString());
    return new Promise<user>((resolve, reject) => {
      axios.patch(endpoint, params)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  createCalibration(id_: number, params: stringKeyObject) {
    let endpoint = API_ENDPOINTS.createCalibration.replace(API_URL_ID_PLACEHOLDER, id_.toString());
    return new Promise<{}>((resolve, reject) => {
      axios.put(endpoint, params)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  updateCalibration(id_: number, params: stringKeyObject) {
    let endpoint = API_ENDPOINTS.updateCalibration.replace(API_URL_ID_PLACEHOLDER, id_.toString());
    return new Promise<{}>((resolve, reject) => {
      axios.patch(endpoint, params)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  sendMessageToContact(id_: number, params: stringKeyObject) {
    let endpoint = API_ENDPOINTS.sendMessageToContact.replace(API_URL_ID_PLACEHOLDER, id_.toString());

    return new Promise<{}>((resolve, reject) => {
      axios.post(endpoint, params)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getAllUsersForCompany(id_: number, params: stringKeyObject) {
    let endpoint = API_ENDPOINTS.getAllUsersForCompany.replace(API_URL_ID_PLACEHOLDER, id_.toString());
    return new Promise<{ count: number, data: user[] }>((resolve, reject) => {
      axios.get<{ count: number, data: user[] }>(
        endpoint, {
          params: this.setLimit(params)
        }
      )
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  createUserForCompany(id_: number, params: stringKeyObject) {
    let endpoint = API_ENDPOINTS.createUserForCompany.replace(API_URL_ID_PLACEHOLDER, id_.toString());
    return new Promise<{}>((resolve, reject) => {
      axios.post(endpoint, params)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getCompanies(params?: stringKeyObject) {
    return new Promise<{ count: number, data: company[] }>((resolve, reject) => {
      axios.get<{ count: number, data: company[] }>(API_ENDPOINTS.getCompanies, {params: this.setLimit(params)})
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          // do not output this error message
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getCompaniesByUser(id_: number, params: stringKeyObject) {
    let endpoint = API_ENDPOINTS.getCompaniesByUser.replace(API_URL_ID_PLACEHOLDER, id_.toString());
    return new Promise<{ count: number, data: company[] }>((resolve, reject) => {
      axios.get<{ count: number, data: company[] }>(endpoint, this.setLimit(params))
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getCompanyRelatedCompanies(id_: number, params: stringKeyObject) {
    let endpoint = API_ENDPOINTS.getCompanyRelatedCompanies.replace(API_URL_ID_PLACEHOLDER, id_.toString());
    return new Promise<{ count: number, data: company[] }>((resolve, reject) => {
      axios.get<{ count: number, data: company[] }>(endpoint, {
        params: this.setLimit(params)
      })
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          // do not output this error message
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getCompanyRelations(id_: number, params: stringKeyObject) {
    let endpoint = API_ENDPOINTS.companyRelation.replace(API_URL_ID_PLACEHOLDER, id_.toString());
    return new Promise<companyRelation[]>((resolve, reject) => {
      axios.get<companyRelation[]>(endpoint, {
        params: this.setLimit(params)
      })
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  addCompanyRelation(companyId: number, relationId: number) {
    let endpoint = API_ENDPOINTS.companyRelation.replace(API_URL_ID_PLACEHOLDER, companyId.toString());
    return new Promise<companyRelation>((resolve, reject) => {
      axios.post(endpoint, {
        relatedCompanyId: relationId
      })
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  updateCompanyRelation(companyId: number, relationId: number, data: stringKeyObject) {
    let endpoint = API_ENDPOINTS.updateCompanyRelation
      .replace(API_URL_ID_PLACEHOLDER, companyId.toString())
      .replace(API_URL_ID_PLACEHOLDER, relationId.toString());
    return new Promise<companyRelation>((resolve, reject) => {
      axios.patch(endpoint, data)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getAllowedCompanyRelations(id_: number, data: stringKeyObject) {
    let endpoint = API_ENDPOINTS.getAllowedCompanyRelations.replace(API_URL_ID_PLACEHOLDER, id_.toString());
    return new Promise<companyRelation[]>((resolve, reject) => {
      axios.put(endpoint, data)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getCompanyRelationsByUser(id_: number, data: stringKeyObject) {
    let endpoint = API_ENDPOINTS.getCompanyRelationsByUser.replace(API_URL_ID_PLACEHOLDER, id_.toString());
    return new Promise<{ data: companyRelation[] }>((resolve, reject) => {
      axios.get(endpoint, data)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  deleteAllCompanyRelations(id_: number) {
    let endpoint = API_ENDPOINTS.deleteAllCompanyRelations.replace(API_URL_ID_PLACEHOLDER, id_.toString());
    return new Promise<{}>((resolve, reject) => {
      axios.delete(endpoint)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  deleteAllUserFromCompanyRelation(id_: number, data: stringKeyObject) {
    let endpoint = API_ENDPOINTS.deleteUserFromCompanyRelation.replace(API_URL_ID_PLACEHOLDER, id_.toString());
    return new Promise<companyRelation[]>((resolve, reject) => {
      axios.delete(endpoint, data)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  deleteUserFromCompanyRelationByUser(companyRelationId: number, userId: number, data: stringKeyObject) {
    let endpoint = API_ENDPOINTS.deleteUserFromCompanyRelation.replace(API_URL_ID_PLACEHOLDER, companyRelationId.toString()) + '' + userId;
    return new Promise<companyRelation[]>((resolve, reject) => {
      axios.delete(endpoint, data)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  activateUserFormCompanyRelation(companyId: number, companyRelationId: number, data: stringKeyObject) {
    let endpoint = API_ENDPOINTS.activateUserFormCompanyRelation.replace(API_URL_ID_PLACEHOLDER, companyId.toString()) + '' + companyRelationId;
    return new Promise<{}>((resolve, reject) => {
      axios.patch(endpoint, data)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  deleteCompanyRelationByUser(userId: number, companyRelationId: number) {
    let endpoint = API_ENDPOINTS.deleteAllCompanyRelations.replace(API_URL_ID_PLACEHOLDER, userId.toString()) + '' + companyRelationId;
    return new Promise<{}>((resolve, reject) => {
      axios.delete(endpoint)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  activateCompanyRealtionByUser(id_: number, params: stringKeyObject, data: stringKeyObject) {
    let endpoint = API_ENDPOINTS.activateCompanyRealtionByUser.replace(API_URL_ID_PLACEHOLDER, id_.toString());
    return new Promise<{}>((resolve, reject) => {
      axios.put(endpoint, data)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  deleteCompanyRelation(parentId: number, relationId: number) {
    let endpoint = API_ENDPOINTS.companyRelation.replace(API_URL_ID_PLACEHOLDER, parentId.toString()) + '/' + relationId;
    return new Promise<{}>((resolve, reject) => {
      axios.delete(endpoint)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getAddressRelatedDevices(id_: number, params: stringKeyObject) {
    let endpoint = API_ENDPOINTS.getAddressRelatedDevices.replace(API_URL_ID_PLACEHOLDER, id_.toString());
    return new Promise<{ count: number, data: device[] }>((resolve, reject) => {
      axios.get<{ count: number, data: device[] }>(endpoint, {
        params: this.setLimit(params)
      })
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getDeviceAddressesFromCompany(id_: number, params: stringKeyObject): Promise<apiResultCountable<address>> {
    let endpoint = API_ENDPOINTS.getDeviceAddressesFromCompany.replace(API_URL_ID_PLACEHOLDER, id_.toString());
    return new Promise<apiResultCountable<address>>((resolve, reject) => {
      axios.get<apiResultCountable<address>>(endpoint, {
        params: this.setLimit(params)
      })
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getAddressesByCompany(companyId: number, params: stringKeyObject): Promise<apiResultCountable<address>> {
    let endpoint = API_ENDPOINTS.addressesByCompany.replace(API_URL_ID_PLACEHOLDER, companyId.toString());
    return new Promise<apiResultCountable<address>>((resolve, reject) => {
      axios.get<apiResultCountable<address>>(endpoint, {
        params: this.setLimit(params)
      })
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  updateCompany(id_: number, params: companyUpdateData) {
    return new Promise<company>((resolve, reject) => {
      let endpoint = API_ENDPOINTS.updateCompany.replace(API_URL_ID_PLACEHOLDER, id_.toString());
      axios.patch<company>(endpoint, params)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  createUserForEmployee(id_: number, params: stringKeyObject) {
    let endpoint = API_ENDPOINTS.createUserForEmployee.replace(API_URL_ID_PLACEHOLDER, id_.toString());
    return new Promise<{}>((resolve, reject) => {
      axios.post(endpoint, params)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  logout() {
    localStorage.removeItem('deviceFilterConfig');
    return axios.get(API_ENDPOINTS.logout, {})
      .then((respons) => {
        this.setState({isAuth: false});
        if (respons.data.url)
          window.location.replace(respons.data.url);
      });
  };

  logoutTest() {
    localStorage.removeItem('deviceFilterConfig');
    return axios.get(API_ENDPOINTS.logout, {})
      .then((respons) => {
        this.setState({isAuth: false});
        if (respons.data.url)
          window.location.reload();
      });
  };

  loginTest(username: string): Promise<{}> {
    console.log('login test');
    return new Promise<{}>((resolve, reject) => {
      axios.post(API_ENDPOINTS.testLogin, {
        username: username,
        password: process.env.REACT_APP_TEST_LOGIN_SECRET
      })
        .then((response) => {
          resolve(response.data);
          this.setState({isAuth: true});
          window.location.reload();
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getLoginUrl(email: string): Promise<{ data: string }> {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios.get<AxiosResponse>(API_ENDPOINTS.getLoginUrl, {
        params: {
          email: email
        },
        transformResponse: (r: string): { url: string } => {
          return JSON.parse(r)
        }
      })
        .then((response) => {
          resolve(response);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  authorize(authParams_: string): Promise<AxiosResponse<any> | void> {
    return axios.get(API_ENDPOINTS.authorize+authParams_)
          .catch((error) => {
            if(error.response.status && errorMessagesLogin.hasOwnProperty(error.response.status)) {
              localStorage.setItem(LOCALSTORAGE_KEY_ERROR_NEXT_LOAD, errorMessagesLogin[error.response.status]['default'])
              localStorage.setItem(LOCALSTORAGE_KEY_ERROR_NEXT_LOAD_DATE, new Date().toString())
            }

            return Promise.reject(error.response);
          });
  }

  getUsers(params: stringKeyObject): Promise<user[] | undefined> {
    return new Promise<user[]>((resolve, reject) => {
      axios.get<user[]>(API_ENDPOINTS.getUsers, {
        params: this.setLimit(params),
        transformResponse: (r: string) => {
          let users: user = JSON.parse(r);
          return users;
        }
      })
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          // do not output this error message
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getUser(): Promise<user | undefined> {
    return new Promise<user>((resolve, reject) => {
      axios.get<user>(API_ENDPOINTS.getUser, {})
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          // do not output this error message
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getUserRoles(userId: string, params: stringKeyObject = {}): Promise<apiResultCountable<userRoleEntry>> {
    let endpoint = API_ENDPOINTS.getUserRoles.replace(API_URL_ID_PLACEHOLDER, userId);
    return new Promise<apiResultCountable<userRoleEntry>>((resolve, reject) => {
      axios.get<apiResultCountable<userRoleEntry>>(endpoint, params)
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getDevice(id_: number): Promise<device | undefined> {
    let endpoint = API_ENDPOINTS.getDevice.replace(API_URL_ID_PLACEHOLDER, id_.toString());

    return new Promise<device>((resolve, reject) => {
      axios.get<device>(
        endpoint,
        {
          transformResponse: (r: string) => {
            let deviceObj: device = JSON.parse(r);
            deviceObj.commentsCount = 0;
            deviceObj.uploadCount = 0;

            return deviceObj;
          }
        }
      )
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getDevices(params: stringKeyObject): Promise<apiResultCountable<device>> {
    return new Promise<apiResultCountable<device>>((resolve, reject) => {
      axios.get<apiResultCountable<device>>(API_ENDPOINTS.getDevices, {
        params: this.setLimit(params),
        transformResponse: (r: string) => {
          let devicesObj: news[] = JSON.parse(r);
          return devicesObj;
        }
      })
        .then((response) => {
          resolve(response.data);
        }, (error) => {
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getNews(params: stringKeyObject): Promise<apiResultCountable<news>> {
    return new Promise<apiResultCountable<news>>((resolve, reject) => {
      axios.get<apiResultCountable<news>>(API_ENDPOINTS.getNews, {
          params: this.setLimit(params),
          transformResponse: (r: string) => {
            let newsObj: news[] = JSON.parse(r);
            return newsObj;
          }
        }
      )
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getSubcategories(categoryId: number, params: stringKeyObject): Promise<{ count: number, data: faqCategory[] }> {
    let endpoint = API_ENDPOINTS.getSubcategories.replace(API_URL_ID_PLACEHOLDER, categoryId.toString());

    return new Promise<{ count: number, data: faqCategory[] }>((resolve, reject) => {
      axios.get<{ count: number, data: faqCategory[] }>(endpoint, {
        params: this.setLimit(params),
      })
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getFaq(params: stringKeyObject): Promise<{ count: number, data: faq[] }> {
    return new Promise<{ count: number, data: faq[] }>((resolve, reject) => {
      axios.get<{ count: number, data: faq[] }>(API_ENDPOINTS.getFaq, {
          params: this.setLimit(params),
          transformResponse: (r: string) => {
            let faqObj: faq[] = JSON.parse(r);
            return faqObj;
          }
        }
      )
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getFaqRootCategories(params: stringKeyObject): Promise<{ count: number, data: faqCategory[] }> {
    return new Promise<{ count: number, data: faqCategory[] }>((resolve, reject) => {
      axios.get<{ count: number, data: faqCategory[] }>(API_ENDPOINTS.getFaqRootCategories, {
          params: this.setLimit(params),
          transformResponse: (r: string) => {
            let faqCategoriesObj: string[] = JSON.parse(r);
            return faqCategoriesObj;
          }
        }
      )
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getHistory(deviceId_: number): Promise<deviceHistory[]> {
    let endpoint = API_ENDPOINTS.getHistory.replace(API_URL_ID_PLACEHOLDER, deviceId_.toString());

    return new Promise<deviceHistory[]>((resolve, reject) => {
      axios.get<deviceHistory[]>(
        endpoint,
        {
          transformResponse: (r: string) => {
            let historyObj: deviceHistory[] = JSON.parse(r);
            return historyObj;
          }
        }
      )
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getAllCompanyContacts(id_: number, params: stringKeyObject): Promise<{ count: number, data: person[] }> {
    let endpoint = API_ENDPOINTS.getAllCompanyContacts.replace(API_URL_ID_PLACEHOLDER, id_.toString());

    return new Promise<{ count: number, data: person[] }>((resolve, reject) => {
      axios.get<{ count: number, data: person[] }>(
        endpoint,
        {
          params: this.setLimit(params),
          transformResponse: (r: string) => {
            let personObj: person[] = JSON.parse(r);
            return personObj;
          }
        }
      )
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getEmployees(id_: number, params: stringKeyObject): Promise<{ count: number, data: person[] }> {
    let endpoint = API_ENDPOINTS.getEmployees.replace(API_URL_ID_PLACEHOLDER, id_.toString());

    return new Promise<{ count: number, data: person[] }>((resolve, reject) => {
      axios.get<{ count: number, data: person[] }>(
        endpoint,
        {
          params: this.setLimit(params),
          transformResponse: (r: string) => {
            let personObj: person[] = JSON.parse(r);
            return personObj;
          }
        }
      )
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  exportDevicesExcel(params: stringKeyObject): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      axios.get<Blob>(API_ENDPOINTS.exportDevicesExcel, {
          params: params,
          responseType: 'blob'
        }
      )
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getErrorUrl(error_: string, errorDescription_: string): Promise<{ data: { url: string } }> {
    return new Promise<{ data: { url: string } }>((resolve, reject) => {
      axios.get(API_ENDPOINTS.getErrorUrl, {
        params: {
          error: error_,
          error_description: errorDescription_,
        },
        transformResponse: (r: string) => {
          return JSON.parse(r);
        }
      })
        .then((response) => {
          resolve(response);
        }, (error: { response: AxiosResponse }) => {
          reject(error);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getDownloads(deviceId_: number): Promise<{ count: number, data: deviceDownload[] }> {
    let endpoint = API_ENDPOINTS.getDownloads.replace(API_URL_ID_PLACEHOLDER, deviceId_.toString());

    return new Promise<{ count: number, data: deviceDownload[] }>((resolve, reject) => {
      axios.get<{ count: number, data: deviceDownload[] }>(
        endpoint,
        {
          transformResponse: (r: string) => {
            let downloadObj: deviceDownload[] = JSON.parse(r);
            return downloadObj;
          }
        }
      )
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getCalibration(deviceId_: number): Promise<{ count: number, data: calibration[] }> {
    let endpoint = API_ENDPOINTS.getCalibration.replace(API_URL_ID_PLACEHOLDER, deviceId_.toString());

    return new Promise<{ count: number, data: calibration[] }>((resolve, reject) => {
      axios.get<{ count: number, data: calibration[] }>(
        endpoint,
        {
          transformResponse: (r: string) => {
            let calibrationObj: calibration[] = JSON.parse(r);
            return calibrationObj;
          }
        }
      )
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getCalibrationCertificates(deviceId: number): Promise<{ data: calibrationCertificate[] }> {
    const endpoint = API_ENDPOINTS.getCalibrationCertificates.replace(API_URL_ID_PLACEHOLDER, deviceId.toString());
    return new Promise<{ data: calibrationCertificate[] }>((resolve, reject) => {
      axios.get<{data: calibrationCertificate[] }>(endpoint, {})
          .then((response) => {
            resolve(response.data);
          }, (error) => {
            reject(error.response);
          })
          .catch((error) => {
            reject(error.response);
          });
    });
  }

  getComponents(deviceId_: number): Promise<{ count: number, data: device[] }> {
    let endpoint = API_ENDPOINTS.getComponents.replace(API_URL_ID_PLACEHOLDER, deviceId_.toString());

    return new Promise<{ count: number, data: device[] }>((resolve, reject) => {
      axios.get<{ count: number, data: device[] }>(
        endpoint,
        {
          transformResponse: (r: string) => {
            let componentObj: device[] = JSON.parse(r);
            return componentObj;
          }
        }
      )
        .then((response) => {
          resolve(response.data);
        }, (error) => {
          reject(error.response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  render() {
    return (
      <ApiContext.Provider
        value={{
          alertManager: this.props.alertManager,
          authorize: this.authorize,
          createCalibration: this.createCalibration,
          createUserForCompany: this.createUserForCompany,
          createUserForEmployee: this.createUserForEmployee,
          createUserRequest: this.createUserRequest,
          createUserInternal: this.createUserInternal,
          getAllCompanyContacts: this.getAllCompanyContacts,
          getAllUsersForCompany: this.getAllUsersForCompany,
          getCalibration: this.getCalibration,
          getCalibrationCertificates: this.getCalibrationCertificates,
          getCompanies: this.getCompanies,
          getCompaniesByUser: this.getCompaniesByUser,
          getCompanyRelatedCompanies: this.getCompanyRelatedCompanies,
          updateCompany: this.updateCompany,
          getCompanyRelations: this.getCompanyRelations,
          addCompanyRelation: this.addCompanyRelation,
          deleteCompanyRelation: this.deleteCompanyRelation,
          updateCompanyRelation: this.updateCompanyRelation,
          getAllowedCompanyRelations: this.getAllowedCompanyRelations,
          getCompanyRelationsByUser: this.getCompanyRelationsByUser,
          deleteAllCompanyRelations: this.deleteAllCompanyRelations,
          deleteCompanyRelationByUser: this.deleteCompanyRelationByUser,
          deleteAllUserFromCompanyRelation: this.deleteAllUserFromCompanyRelation,
          deleteUserFromCompanyRelationByUser: this.deleteUserFromCompanyRelationByUser,
          activateUserFormCompanyRelation: this.activateUserFormCompanyRelation,
          activateCompanyRealtionByUser: this.activateCompanyRealtionByUser,
          getComponents: this.getComponents,
          getDevice: this.getDevice,
          getDevices: this.getDevices,
          getAddressRelatedDevices: this.getAddressRelatedDevices,
          getDeviceAddressesFromCompany: this.getDeviceAddressesFromCompany,
          getAddressesByCompany: this.getAddressesByCompany,
          getDownloads: this.getDownloads,
          getEmployees: this.getEmployees,
          exportDevicesExcel: this.exportDevicesExcel,
          getErrorUrl: this.getErrorUrl,
          getFaq: this.getFaq,
          getFaqRootCategories: this.getFaqRootCategories,
          getHistory: this.getHistory,
          getLoginUrl: this.getLoginUrl,
          getNews: this.getNews,
          getSubcategories: this.getSubcategories,
          getUser: this.getUser,
          getUsers: this.getUsers,
          getUserRoles: this.getUserRoles,
          loginTest: this.loginTest,
          logout: this.logout,
          logoutTest: this.logoutTest,
          sendMessageToContact: this.sendMessageToContact,
          updateCalibration: this.updateCalibration,
          updateUser: this.updateUser,
        }}
      >
        {this.props.children}
      </ApiContext.Provider>
    )
  }
}

export default ApiProvider;
