import React from "react";
import device from "../../types/device";
import news from "../../types/news";
import faq from "../../types/faq";
import deviceHistory from "../../types/deviceHistory";
import deviceDownload from "../../types/deviceDownload";
import user from "../../types/user";
import faqCategory from "../../types/faqCategory";
import person from "../../types/Person";
import calibration from "../../types/calibration";
import {company, companyRelation, companyUpdateData} from "../../types/company";
import {AlertManager} from "react-alert";
import {AxiosResponse} from "axios";
import address from "../../types/address";
import {userRoleEntry} from "../../types/userRole";
import {apiResultCountable} from "../Provider/ApiProvider";
import calibrationCertificate from "../../types/calibrationCertificate";

type ApiContextProps = {
  authorize: (authParams_: string) => Promise<AxiosResponse<any> | void>,
  createCalibration: (id_: number, params: object) => void,
  createUserForCompany: (id_: number, params: object) => void,
  createUserForEmployee: (id_: number, params: object) => void,
  createUserRequest: (form: string) => void,
  createUserInternal: (form: string) => void,
  getAllCompanyContacts: (id_: number, params: object) => Promise<{ count: number, data: person[] }>,
  getAllUsersForCompany: (id_: number, params: object) => Promise<{ count: number, data: user[] }>,
  getCalibration: (id_: number) => Promise<{ count: number, data: calibration[] }>,
  getCalibrationCertificates: (deviceId: number) => Promise<{ data: calibrationCertificate[] }>,
  getCompanies: () => Promise<{ count: number, data: company[] }>,
  getCompaniesByUser: (id_: number, params: object) => Promise<{ count: number, data: company[] }>,
  getCompanyRelatedCompanies: (id_: number, params: object) => Promise<{ count: number, data: company[] }>,
  getAllowedCompanyRelations: (id_: number, data: {}) => Promise<companyRelation[]>,
  getCompanyRelationsByUser: (id_: number, data: {}) => Promise<{ data: companyRelation[] }>,
  deleteAllCompanyRelations: (id_: number, params: object) => Promise<{}>,
  deleteCompanyRelationByUser: (userId: number, companyRelationId: number, params: object) => Promise<{}>,
  activateCompanyRealtionByUser: (id_: number, params: object, data: {}) => Promise<{}>,
  updateCompany: (id_: number, params: companyUpdateData) => Promise<company>,
  getCompanyRelations: (id_: number, params: object) => Promise<companyRelation[]>,
  addCompanyRelation: (companyId: number, relationId: number) => Promise<companyRelation>,
  deleteCompanyRelation: (parentId: number, childId: number) => Promise<{}>,
  deleteAllUserFromCompanyRelation: (id_: number, params: object) => Promise<{}>,
  deleteUserFromCompanyRelationByUser: (companyRelationId: number, userId: number, data: {}) => Promise<{}>,
  activateUserFormCompanyRelation: (companyId: number, companyRelationId: number, data: {}) => Promise<{}>,
  updateCompanyRelation: (parentId: number, childId: number, data: {}) => Promise<companyRelation>,
  getComponents: (id_: number) => Promise<{ count: number, data: device[] }>,
  getDevice: (id_: number) => Promise<device | undefined>,
  getDevices: (params: object) => Promise<{ count: number, data: device[] }>
  getAddressRelatedDevices: (id_: number, params: object) => Promise<{ count: number, data: device[] }>,
  getDeviceAddressesFromCompany: (id_: number, params: object) => Promise<{ count: number, data: address[] }>,
  getAddressesByCompany: (companyId: number, params: object) => Promise<{ count: number, data: address[] }>,
  getDownloads: (id_: number) => Promise<{ count: number, data: deviceDownload[] }>,
  getEmployees: (id_: number, params: object) => Promise<{ count: number, data: person[] }>,
  exportDevicesExcel: (params: object) => Promise<Blob>,
  getErrorUrl: (error_: string, errorDescription_: string) => Promise<{ data: { url: string } }>,
  getFaq: (params: object) => Promise<{ count: number, data: faq[] }>,
  getFaqRootCategories: (params: object) => Promise<{ count: number, data: faqCategory[] }>,
  getHistory: (id_: number) => Promise<deviceHistory[]>,
  getLoginUrl: (username: string) => void,
  getNews: (params: object) => Promise<{ count: number, data: news[] }>,
  getSubcategories: (categoryId: number, params: object) => Promise<{ count: number, data: faqCategory[] }>,
  getUser: () => Promise<user | undefined>,
  getUsers: (params_: object) => Promise<user[] | undefined>,
  getUserRoles: (userId: string, params: object) => Promise<apiResultCountable<userRoleEntry>>,
  loginTest: (username: string, password: string) => void,
  logout: () => void,
  logoutTest: () => void,
  sendMessageToContact: (id_: number, params: object) => void,
  updateCalibration: (id_: number, params: object) => void,
  updateUser: (id_: number, params: object) => void,
  alertManager?: AlertManager
};

const ApiContext = React.createContext<ApiContextProps>({
  getLoginUrl: (email: string) => void 0,
  authorize: (authParams_: string) => new Promise<AxiosResponse<any> | void>((resolve, reject) => {
    resolve();
  }),
  loginTest: (username: string, password: string) => void 0,
  createUserRequest: (form: string) => void 0,
  createUserInternal: (form: string) => void 0,
  logout: () => void 0,
  logoutTest: () => void 0,
  getDevices: (params: object) => new Promise<{ count: number, data: device[] }>((resolve, reject) => {
    resolve();
  }),
  getNews: (params: object) => new Promise<{ count: number, data: news[] }>((resolve, reject) => {
    resolve();
  }),
  getSubcategories: (categoryId: number) => new Promise<{ count: number, data: faqCategory[] }>((resolve, reject) => {
    resolve();
  }),
  getFaq: (params: object) => new Promise<{ count: number, data: faq[] }>((resolve, reject) => {
    resolve();
  }),
  getDevice: (id_: number) => new Promise<device>((resolve, reject) => {
    resolve();
  }),
  getAddressRelatedDevices: (id_: number, params: object) => new Promise<{ count: number, data: device[] }>((resolve, reject) => {
    resolve();
  }),
  getDeviceAddressesFromCompany: (id_: number, params: object) => new Promise<{ count: number, data: address[] }>((resolve, reject) => {
    resolve();
  }),
  getAddressesByCompany: (comapnaId: number, params: object) => new Promise<{ count: number, data: address[] }>((resolve, reject) => {
    resolve();
  }),
  getHistory: (id_: number) => new Promise<deviceHistory[]>((resolve, reject) => {
    resolve();
  }),
  getDownloads: (id_: number) => new Promise<{ count: number, data: deviceDownload[] }>((resolve, reject) => {
    resolve();
  }),
  getCalibration: (id_: number) => new Promise<{ count: number, data: calibration[] }>((resolve, reject) => {
    resolve();
  }),
  getCalibrationCertificates: (deviceId: number) => new Promise<{ data: calibrationCertificate[] }>((resolve, reject) => {
    resolve();
  }),
  getComponents: (id_: number) => new Promise<{ count: number, data: device[] }>((resolve, reject) => {
    resolve();
  }),
  getUser: () => new Promise<user>((resolve, reject) => {
    resolve();
  }),
  getUsers: () => new Promise<user[]>((resolve, reject) => {
    resolve();
  }),
  getUserRoles: (userId, params) => new Promise<apiResultCountable<userRoleEntry>>((resolve, reject) => {
    resolve();
  }),
  getCompanies: () => new Promise<{ count: number, data: company[] }>((resolve, reject) => {
    resolve();
  }),
  getCompaniesByUser: (id_: number, params: object) => new Promise<{ count: number, data: company[] }>((resolve, reject) => {
    resolve();
  }),
  getCompanyRelatedCompanies: (id_: number, params: object) => new Promise<{ count: number, data: company[] }>((resolve, reject) => {
    resolve();
  }),
  getCompanyRelationsByUser: (id_: number, params: object) => new Promise<{ data: companyRelation[] }>((resolve, reject) => {
    resolve();
  }),
  getAllowedCompanyRelations: (id_: number, data: {}) => new Promise<companyRelation[]>((resolve, reject) => {
    resolve();
  }),
  deleteAllCompanyRelations: (id_: number, params: object) => new Promise<{}>((resolve, reject) => {
    resolve();
  }),
  activateCompanyRealtionByUser: (id_: number, params: object, data: {}) => new Promise<{}>((resolve, reject) => {
    resolve();
  }),
  deleteCompanyRelationByUser: (userId: number, companyRelationId: number, params: object) => new Promise<{}>((resolve, reject) => {
    resolve();
  }),
  updateCompany: (id_: number, params: companyUpdateData) => new Promise<company>((resolve, reject) => {
    resolve();
  }),
  getCompanyRelations: (id_: number, params: object) => new Promise<companyRelation[]>((resolve, reject) => {
    resolve();
  }),
  addCompanyRelation: (companyId: number, relationId: number) => new Promise<companyRelation>((resolve, reject) => {
    resolve();
  }),
  deleteAllUserFromCompanyRelation: (id_: number, params: object) => new Promise<{}>((resolve, reject) => {
    resolve();
  }),
  deleteUserFromCompanyRelationByUser: (companyRelationId: number, userId: number, data: {}) => new Promise<{}>((resolve, reject) => {
    resolve();
  }),
  activateUserFormCompanyRelation: (companyId: number, companyRelationId: number, data: {}) => new Promise<{}>((resolve, reject) => {
    resolve();
  }),
  deleteCompanyRelation: (parentId: number, childId: number) => new Promise<{}>((resolve, reject) => {
    resolve();
  }),
  updateCompanyRelation: (parentId: number, childId: number, data: {}) => new Promise<companyRelation>((resolve, reject) => {
    resolve();
  }),
  sendMessageToContact: (id_: number, params: object) => void 0,
  getFaqRootCategories: (params: object) => new Promise<{ count: number, data: faqCategory[] }>((resolve, reject) => {
    resolve();
  }),
  getAllCompanyContacts: (id_: number, params: object) => new Promise<{ count: number, data: person[] }>((resolve, reject) => {
    resolve();
  }),
  getAllUsersForCompany: (id_: number, params: object) => new Promise<{ count: number, data: user[] }>((resolve, reject) => {
    resolve();
  }),
  createUserForCompany: (id_: number, params: object) => void 0,
  createUserForEmployee: (id_: number, params: object) => void 0,
  updateUser: (id_: number, params: object) => void 0,
  createCalibration: (id_: number, params: object) => void 0,
  updateCalibration: (id_: number, params: object) => void 0,
  getEmployees: (id_: number, params?: object) => new Promise<{ count: number, data: person[] }>((resolve, reject) => {
    resolve();
  }),
  exportDevicesExcel: (params?: object) => new Promise<Blob>((resolve, reject) => {
    resolve();
  }),
  getErrorUrl: (error_: string, errorDescription_: string) => new Promise<{ data: { url: string } }>((resolve, reject) => {
    resolve();
  }),
  alertManager: undefined
});

export default ApiContext;
