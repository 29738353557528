import {CURRENT_USER_STORE_ID} from '../components/User/constants'
import user from '../types/user'

export default class UserService {
  static saveCurrentUser(user: user) {
    localStorage.setItem(CURRENT_USER_STORE_ID, user.id.toString())
  }

  static deleteCurrentUserId() {
    localStorage.removeItem(CURRENT_USER_STORE_ID)
  }

  static getCurrentUserId(): string | null {
    return localStorage.getItem(CURRENT_USER_STORE_ID)
  }
}