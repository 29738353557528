import React, {
  ChangeEvent,
} from 'react'
import Loader from '../Loader'
import InputText from '../Form/InputText'
import user from '../../types/user'
import PersonCard from '../PersonCard'
import BackButton from '../BackButton'
import Button from '../Button'
import PopUp from '../PopUp'
import CreateAccount from '../User/CreateAccount'
import LoadingIndicator from '../LoadingIndicator'
import ApiContext from '../Context/ApiContext'
import {OptionType} from '../Select/SelectTypes'
import {USER_ROLE_COMPANY_ADMIN, USER_ROLE_USER} from '../../types/userRole'
import UserRoleService from '../../services/UserRoleService'
import Select from '../Select/Select'
import {TYPING_DELAY} from '../../types/filter'
import {ValueType} from 'react-select/src/types'
import {EDIT_ACCOUNT_MODE_TEAM} from '../EditAccount/constants'
import FilterService from '../../services/FilterService'
import UserService from '../../services/UserService'

const FILTER_STATUS_OPTIONS: OptionType[] = [
  {value: '', label: 'Select status'},
  {value: 'active', label: 'active'},
  {value: 'inactive', label: 'inactive'},
]

const FILTER_ROLE_OPTIONS: OptionType[] = [
  {value: '', label: 'Select role'},
  {value: USER_ROLE_COMPANY_ADMIN, label: UserRoleService.getRoleLabel(USER_ROLE_COMPANY_ADMIN)},
  {value: USER_ROLE_USER, label: UserRoleService.getRoleLabel(USER_ROLE_USER)},
]

const ITEMS_PER_PAGE = 5

type myTeamProps = {
  user: user | undefined
}

type myTeamState = {
  team: user[]
  loaded: boolean
  loadedMore: boolean
  statusFilter: OptionType | null
  roleFilter: OptionType | null
  search: string
  timeout: any
  filtered: boolean
  showPopup: boolean
  itemCount: number
  itemMax: number
  limit: number
  offset: number
}

class MyTeam extends React.Component<myTeamProps, myTeamState> {
  constructor(props: myTeamProps) {
    super(props)

    let filter = {
      search: '',
      statusFilter: FILTER_STATUS_OPTIONS[0],
      roleFilter: FILTER_ROLE_OPTIONS[0],
      itemCount: ITEMS_PER_PAGE,
      limit: ITEMS_PER_PAGE,
    }
    const storedFilter = this.getFilterStore()
    if (storedFilter && typeof storedFilter === 'object') {
      filter = {
        ...filter,
        ...storedFilter,
      }
    }

    this.state = {
      team: [],
      loaded: false,
      loadedMore: true,
      statusFilter: filter.statusFilter,
      roleFilter: filter.roleFilter,
      search: '',
      timeout: '',
      filtered: false,
      showPopup: false,
      itemCount: (filter.itemCount > filter.limit) ? filter.itemCount : filter.limit, // has to be equal to limit, is set @componentDidMount
      itemMax: 0,
      limit: filter.limit, // sets initially loaded users and number of "load more" users
      offset: 0,
    }

    this.handleTeamRequest = this.handleTeamRequest.bind(this)
    this.onChangeInput = this.onChangeInput.bind(this)
    this.onChangeSelectRole = this.onChangeSelectRole.bind(this)
    this.onChangeSelectStatus = this.onChangeSelectStatus.bind(this)
    this.setFilter = this.setFilter.bind(this)
    this.onClickCreateAccount = this.onClickCreateAccount.bind(this)
    this.onClickCancel = this.onClickCancel.bind(this)
  }

  componentDidMount(): void {
    this.handleTeamRequest(false, this.state.itemCount, true)
  }

  handleTeamRequest(filter?: boolean, limit?: number, isFirstload: boolean = false) {
    if (isFirstload) {
      this.setState({
        offset: 0,
        loaded: false,
      })
    }
    const params = {
      limit: limit ? limit : this.state.limit,
      offset: isFirstload ? 0 : this.state.offset,
    }

    if (this.state.roleFilter && this.state.roleFilter.value !== '') {
      Object.assign(params, {
        'filter[users][roles]': this.state.roleFilter.value,
      })
    }

    if (this.state.statusFilter && this.state.statusFilter.value !== '') {
      Object.assign(params, {
        'filter[users][status]': this.state.statusFilter.value,
      })
    }

    let search = this.state.search.trim()
    if (search !== '') {
      Object.assign(params, {
        'filter[users][firstName]': search,
        'filter[users][lastName]': search,
        'filter[users][email]': search,
      })
    }

    this.context.getAllUsersForCompany(this.props.user?.company?.id, params).then((teamObj: {
      count: number,
      data: user[]
    }) => {
      this.setState({
        team: filter ? teamObj.data : this.state.team.concat(teamObj.data),
        itemMax: teamObj.count,
        loadedMore: true,
        loaded: true,
      }, () => {
        if (isFirstload) {
          this.scrollToLastEditedUser()
          UserService.deleteCurrentUserId()
        }
      })
    }, () => {
      this.setState({
        loaded: true,
        loadedMore: true,
      })
    })
  }

  scrollToLastEditedUser() {
    let currentUserId = UserService.getCurrentUserId()
    if (currentUserId) {
      let scrollTarget = document.querySelector('[data-user="' + currentUserId + '"]')
      if (scrollTarget)
        scrollTarget.scrollIntoView()
    }
  }

  storeFilter() {
    localStorage.setItem(FilterService.filterStoreIds.MyTeam, JSON.stringify({
      statusFilter: this.state.statusFilter,
      roleFilter: this.state.roleFilter,
      search: this.state.search,
      itemCount: this.state.itemCount,
      limit: this.state.limit,
    }))
  }

  getFilterStore() {
    let config = localStorage.getItem(FilterService.filterStoreIds.MyTeam)
    return config ? JSON.parse(config) : ''
  }

  setFilter(triggerRequest: boolean = true) {
    this.setState({
      team: [],
      filtered: false,
      loaded: false,
      offset: 0,
      itemCount: this.state.limit,
    }, () => {
      this.storeFilter()
      // callback after asynchron state changes needed for select filter changes
      if (triggerRequest) {
        this.handleTeamRequest(true)
      }
    })
  }

  onChangeInput(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault()
    let searchText = e.target.value
    if (searchText !== this.state.search) {
      clearTimeout(this.state.timeout)
      this.setState({
        search: e.target.value,
        timeout: setTimeout(() => {
          this.setFilter()
        }, TYPING_DELAY),
      })
    }
  }

  onClickCreateAccount() {
    this.setState({
      showPopup: true,
    })
  }

  onClickCancel() {
    this.setState({
      showPopup: false,
    })
  }

  onChangeSelectRole(value: ValueType<OptionType, false> | null): void {
    this.setState({
      roleFilter: value,
    }, this.setFilter)
  }

  onChangeSelectStatus(value: ValueType<OptionType, false> | null): void {
    this.setState({
      statusFilter: value,
    }, this.setFilter)
  }

  onClickLoadMore() {
    this.setState({
      loadedMore: false,
      offset: this.state.offset + this.state.limit,
      itemCount: this.state.itemCount + this.state.limit,
    }, () => {
      this.storeFilter()
      this.handleTeamRequest()
    })
  }

  onUpdateUser(user?: user) {
    if (!user) return
    UserService.saveCurrentUser(user)
    this.handleTeamRequest(true, this.state.itemCount, true)
  }

  render() {
    return (
      <div className="teamoverview__wrapper">
        <PopUp onClickCloseIcon={this.onClickCancel} showPopup={this.state.showPopup}>
          <CreateAccount handleTeamRequest={this.handleTeamRequest} user={this.props.user} onClickCancel={this.onClickCancel}/>
        </PopUp>
        <div id="teamoverview" className="container">
          <h1 className="pagetitle">My Team</h1>
          <BackButton to="/">
            Back to home
          </BackButton>
          <div className="contentbox">
            <div className="team__filter__wrapper">
              <InputText
                placeholder="Search"
                mode="highlighted"
                id="search"
                onChange={this.onChangeInput}
                value={this.state.search}
                showReset={!!(this.state.search) && this.state.search !== ''}
                onClickReset={() => {
                  this.setState({search: ''}, this.setFilter)
                }}
              />

              <Select
                options={FILTER_STATUS_OPTIONS}
                value={this.state.statusFilter}
                // @ts-ignore
                onChange={this.onChangeSelectStatus}
                menuIsOpen={true}
              />

              <Select
                options={FILTER_ROLE_OPTIONS}
                value={this.state.roleFilter}
                // @ts-ignore
                onChange={this.onChangeSelectRole}
                menuIsOpen={true}
              />

              <Button className="team__create__account__btn" onClick={this.onClickCreateAccount}>
                <span>Create account</span>
              </Button>
            </div>
            {this.state.loaded ? (
              <>
                {this.state.team ? (
                  <>
                    {this.state.team.map((teamMember, i_) => {
                      return (
                        <PersonCard
                          key={i_}
                          mode={EDIT_ACCOUNT_MODE_TEAM}
                          updateUser={() => this.onUpdateUser(teamMember)}
                          user={this.props.user}
                          teamMember={teamMember}
                          title={teamMember.firstName}
                        />
                      )
                    })}
                  </>
                ) : (
                  <h3>No Team found.</h3>
                )}
              </>
            ) : <Loader mode="overview"/>
            }
            {this.state.team.length > 0 && (
              this.state.itemCount < this.state.itemMax &&
              <div className="loading__indicator__wrapper">
                <div onClick={this.onClickLoadMore.bind(this)}>
                  <LoadingIndicator loading={!this.state.loadedMore} text="Show more"/>
                </div>
              </div>
            )
            }
          </div>
        </div>
      </div>
    )
  }
}

MyTeam.contextType = ApiContext

export default MyTeam
