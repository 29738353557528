import React from 'react'
import CloseIcon from './CloseIcon'
import classNames from 'classnames'

type createUserProps = {
  showPopup: boolean,
  onClickCloseIcon: () => void,
  id?: string
  size?: 'lg'
}

class PopUp extends React.Component<createUserProps, {}> {
  render() {

    const containerClasses = classNames({
      'popup__container': true,
      ['popup--' + this.props.size]: !!this.props.size,
    })

    return (
      this.props.showPopup && (
        <>
          <div className="bg__overlay"></div>
          <div id={this.props.id} className={containerClasses}>
            <div className="popup__wrapper white-bg">
              <div onClick={this.props.onClickCloseIcon} className="popup__icon--close">
                <CloseIcon/>
              </div>
              <div className="popup__scroll">
                {this.props.children}
              </div>
            </div>
          </div>
        </>
      )
    )
  }
}

export default PopUp
