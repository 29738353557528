const API_ROOT_PATH = (process.env.REACT_APP_API_BASE_URL || '').replace('{hostname}', window.location.host);
export const API_ROOT_PATH_V2 = API_ROOT_PATH + 'v2/';
export const API_URL_ID_PLACEHOLDER = '$id_';

export const API_ENDPOINTS = {
  // Authentication
  authorize: API_ROOT_PATH_V2 + 'auth/',
  testLogin: API_ROOT_PATH_V2 + 'login/',
  logout: API_ROOT_PATH + 'logout/',
  getLoginUrl: API_ROOT_PATH_V2 + 'login-url/',

  //Address
  getAddressRelatedDevices: API_ROOT_PATH_V2 + 'addresses/' + API_URL_ID_PLACEHOLDER + '/devices/',
  getDeviceAddressesFromCompany: API_ROOT_PATH_V2 + 'companies/' + API_URL_ID_PLACEHOLDER + '/company-relations/devices/addresses/',
  addressesByCompany: API_ROOT_PATH_V2 + 'companies/' + API_URL_ID_PLACEHOLDER + '/addresses/',

  // Calibration
  updateCalibration: API_ROOT_PATH_V2 + 'calibrations/' + API_URL_ID_PLACEHOLDER + '',
  createCalibration: API_ROOT_PATH_V2 + 'devices/' + API_URL_ID_PLACEHOLDER + '/calibration/',
  getCalibration: API_ROOT_PATH_V2 + 'devices/' + API_URL_ID_PLACEHOLDER + '/calibration/',
  getCalibrationCertificates: API_ROOT_PATH_V2 + 'devices/' + API_URL_ID_PLACEHOLDER + '/calibration-certificates',

  // Companies
  createUserForCompany: API_ROOT_PATH_V2 + 'companies/' + API_URL_ID_PLACEHOLDER + '/users/',
  getAllUsersForCompany: API_ROOT_PATH_V2 + 'companies/' + API_URL_ID_PLACEHOLDER + '/users/',
  getCompanies: API_ROOT_PATH_V2 + 'companies/',
  getCompaniesByUser: API_ROOT_PATH_V2 + 'users/' + API_URL_ID_PLACEHOLDER + '/companies/',
  getCompanyRelatedCompanies: API_ROOT_PATH_V2 + 'companies/' + API_URL_ID_PLACEHOLDER + '/companies/',
  updateCompany: API_ROOT_PATH_V2 + 'companies/' + API_URL_ID_PLACEHOLDER + '',
  getCompanyRelationsByUser: API_ROOT_PATH_V2 + 'users/' + API_URL_ID_PLACEHOLDER + '/company-relations/',
  getAllowedCompanyRelations: API_ROOT_PATH_V2 + 'users/' + API_URL_ID_PLACEHOLDER + '/allowed-company-relations/',
  deleteAllCompanyRelations: API_ROOT_PATH_V2 + 'users/' + API_URL_ID_PLACEHOLDER + '/company-relations/',
  deleteCompanyRelationByUser: API_ROOT_PATH_V2 + 'users/' + API_URL_ID_PLACEHOLDER + '/company-relations/',
  activateCompanyRealtionByUser: API_ROOT_PATH_V2 + 'users/' + API_URL_ID_PLACEHOLDER + '/allowed-company-relations/',

  // Company relations
  companyRelation: API_ROOT_PATH_V2 + 'company-relations/' + API_URL_ID_PLACEHOLDER,
  updateCompanyRelation: API_ROOT_PATH_V2 + 'companies/' + API_URL_ID_PLACEHOLDER + '/company-relations/' + API_URL_ID_PLACEHOLDER,
  deleteUserFromCompanyRelation: API_ROOT_PATH_V2 + 'company-relations/' + API_URL_ID_PLACEHOLDER + '/users/',
  activateUserFormCompanyRelation: API_ROOT_PATH_V2 + 'companies/' + API_URL_ID_PLACEHOLDER + '/company-relations/',

  // Contacts
  getAllCompanyContacts: API_ROOT_PATH_V2 + 'company/' + API_URL_ID_PLACEHOLDER + '/contacts/',

  // Devices
  getDevices: API_ROOT_PATH_V2 + 'devices/',
  getDevice: API_ROOT_PATH_V2 + 'devices/' + API_URL_ID_PLACEHOLDER,
  getDownloads: API_ROOT_PATH_V2 + 'devices/' + API_URL_ID_PLACEHOLDER + '/downloads/',
  getComponents: API_ROOT_PATH_V2 + 'devices/' + API_URL_ID_PLACEHOLDER + '/components/',
  getHistory: API_ROOT_PATH_V2 + 'devices/' + API_URL_ID_PLACEHOLDER + '/history/',

  // Employees
  getEmployees: API_ROOT_PATH_V2 + 'companies/' + API_URL_ID_PLACEHOLDER + '/employees/',

  // Export
  exportDevicesExcel: API_ROOT_PATH_V2 + 'export/devices.xlsx',

  // FAQ
  getFaq: API_ROOT_PATH_V2 + 'faq/',

  // Category
  getFaqRootCategories: API_ROOT_PATH_V2 + 'faq/categories/',
  getSubcategories: API_ROOT_PATH_V2 + 'categories/' + API_URL_ID_PLACEHOLDER + '/categories/',

  // Mails
  sendMessageToContact: API_ROOT_PATH_V2 + 'contacts/' + API_URL_ID_PLACEHOLDER + '/messages/',

  // News
  getNews: API_ROOT_PATH_V2 + 'news/',

  // Registration
  createUser: API_ROOT_PATH_V2 + 'registrations/',
  createUserInternal: API_ROOT_PATH_V2 + 'internal-registrations/',

  // User
  updateUser: API_ROOT_PATH_V2 + 'users/' + API_URL_ID_PLACEHOLDER,
  getUser: API_ROOT_PATH_V2 + 'users/me/',
  getUsers: API_ROOT_PATH_V2 + 'users/',
  createUserForEmployee: API_ROOT_PATH_V2 + 'employees/' + API_URL_ID_PLACEHOLDER + '/user/',
  getUserRoles: API_ROOT_PATH_V2 + 'users/' + API_URL_ID_PLACEHOLDER + '/roles/',

  // Misc
  getErrorUrl: API_ROOT_PATH_V2 + 'error-url/',

};

export const MAX_ENTRIES_PER_REQUEST = 1000;