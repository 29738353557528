import React, {ChangeEvent} from 'react'
import classNames from 'classnames'
import countries from 'i18n-iso-countries'
import countryConfig from 'i18n-iso-countries/langs/en.json'

interface selectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  addEmptyOption?: boolean
  emptyOptionLabel?: string
  emptyOptionValue?: string
  additionalClasses?: string
}

export default class CountrySelect extends React.Component<selectProps, {}> {
  static defaultProps = {
    addEmptyOption: true,
    emptyOptionLabel: 'Select a country',
    emptyOptionValue: '',
  }

  constructor(props: selectProps) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    countries.registerLocale(countryConfig)
  }

  handleChange(e: ChangeEvent<HTMLSelectElement>) {
    if (this.props.onChange) {
      this.props.onChange(e)
    }
  }

  render() {
    let {
      additionalClasses,
      addEmptyOption,
      emptyOptionLabel,
      emptyOptionValue,
      ...defaultProps
    } = this.props
    let classes = {
      'form-control': true,
      additionalClasses: !!additionalClasses,
    }
    const options = countries.getNames(countryConfig.locale)

    return (
      <div className="form-group">
        <select onChange={this.handleChange} className={classNames(classes)} {...defaultProps}>
          {this.props.addEmptyOption &&
            <option value={emptyOptionValue}>{emptyOptionLabel}</option>
          }
          {Object.keys(options).map(iso2Code =>
            <option value={iso2Code} key={iso2Code}>{options[iso2Code]}</option>)}
        </select>
      </div>
    )
  }
}
