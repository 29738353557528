import React, {ChangeEvent} from 'react';
import user from "../../types/user";
import ApiContext from "../Context/ApiContext";
import BackButton from "../BackButton";
import InputText from "../Form/InputText";
import Select from "../Select/Select";
import classNames from "classnames";
import Moment from "react-moment";
import DaterangePicker from "../DaterangePicker";
import Loader from "../Loader";
import DeviceAddressEntry from "./DeviceAddressEntry";
import {OptionType} from "../Select/SelectTypes";
import {ValueType} from "react-select/src/types";
import {company} from "../../types/company";
import address from "../../types/address";
import {AxiosResponse} from "axios";
import {stringKeyObject} from "../../types/common";
import moment from "moment";
import Button from "../Button";
import {sortDirection, SORT_DIRECTION_ASC, TYPING_DELAY} from "../../types/filter";
import PageService, {PAGE_HOME, PAGE_PRODUCT_ADRESSES} from "../../services/PageService";
import FilterService, {
  NAMED_FILTER_DEVICE_ADDRESSES,
  namedFilterFieldDeviceAddresses,
  namedFilterParamConfig
} from "../../services/FilterService";
import DeviceService from "../../services/DeviceService";
import AddressService from "../../services/AddressService";
import LoadingIndicator from "../LoadingIndicator";

type filterConfig = {
  search: string
  companies: OptionType[]
  addresses: OptionType[]
  buyDateStart?: string
  buyDateEnd?: string
  nextCalibration: OptionType | null
  sortBy: OptionType | null
  limit: number
  itemCount: number
}

type deviceAddressOverviewProps = {
  user: user | undefined
}

type deviceAddressOverviewState = {
  addresses: address[]
  loaded: boolean
  resultsLoading: boolean
  filterChangeIteration: number
  filterSearch: string
  filterCompanyOptions: OptionType[]
  filterCompanySelectedValues: OptionType[]
  filterCompanyLoading: boolean
  filterAddressOptions: OptionType[]
  filterAddressSelectedValues: OptionType[]
  filterAddressLoading: boolean
  filterBuyDateStart: Date | undefined
  filterBuyDateEnd: Date | undefined
  filterNextCalibration: OptionType | null
  sortBy: OptionType | null
  showBuyDateLabel: boolean
  showBuyDatePicker: boolean
  typingSearchTimeout?: ReturnType<typeof setTimeout>
  excelLoading: boolean
  limit: number
  offset: number
  itemsMax: number
  itemCount: number
  loadingMore: boolean
}

const SORT_BY_OPTIONS: OptionType[] = [
  {
    value: 'dateOfPurchase',
    label: 'Sort devices by: Buy date'
  },
  {
    value: 'calibration.calibrationDate',
    label: 'Sort devices by: Next action'
  }
];

const SORT_BY_DIRECTIONS: stringKeyObject<sortDirection> = {
  dateOfPurchase: 'DESC',
  'calibration.calibrationDate': 'DESC'
};

const CALIBRATION_OPTIONS: OptionType[] = [
  {value: '', label: 'Next action: all'},
  {value: 7, label: 'Next action: 1 week'},
  {value: 30, label: 'Next action: 1 month'},
  {value: 90, label: 'Next action: 3 months'},
  {value: 180, label: 'Next action: 6 months'},
  {value: 360, label: 'Next action: 1 year'}
];

const ITEMS_PER_PAGE = 20;
const FILTER_DEFAULT_VALUES = {
  search: '',
  companies: [],
  addresses: [],
  buyDateStart: undefined,
  buyDateEnd: undefined,
  nextCalibration: CALIBRATION_OPTIONS[0],
  sortBy: SORT_BY_OPTIONS[0],
  limit: ITEMS_PER_PAGE,
  itemCount: ITEMS_PER_PAGE
};

class DeviceAddressOverview extends React.Component<deviceAddressOverviewProps, deviceAddressOverviewState> {
  static pageConfig = PageService.pagesConfigurations[PAGE_PRODUCT_ADRESSES];

  constructor(props: deviceAddressOverviewProps) {
    super(props);

    let filterConfig: filterConfig = FILTER_DEFAULT_VALUES;
    let savedFilterConfig: filterConfig | null = this.getSavedFilterConfig();
    if (savedFilterConfig) {
      filterConfig = {
        ...filterConfig,
        ...savedFilterConfig
      };
    }

    this.state = {
      addresses: [],
      loaded: false,
      resultsLoading: true,
      filterChangeIteration: 1,
      filterSearch: filterConfig.search,
      filterCompanyOptions: [],
      filterCompanySelectedValues: filterConfig.companies,
      filterCompanyLoading: false,
      filterAddressOptions: [],
      filterAddressSelectedValues: filterConfig.addresses,
      filterAddressLoading: false,
      filterBuyDateStart: filterConfig.buyDateStart ? new Date(filterConfig.buyDateStart) : FILTER_DEFAULT_VALUES.buyDateStart,
      filterBuyDateEnd: filterConfig.buyDateEnd ? new Date(filterConfig.buyDateEnd) : FILTER_DEFAULT_VALUES.buyDateEnd,
      filterNextCalibration: filterConfig.nextCalibration,
      showBuyDateLabel: !!(filterConfig.buyDateStart && filterConfig.buyDateEnd),
      showBuyDatePicker: false,
      sortBy: filterConfig.sortBy,
      excelLoading: false,
      limit: filterConfig.limit,
      offset: 0,
      itemsMax: 0,
      itemCount: (filterConfig.itemCount > FILTER_DEFAULT_VALUES.limit) ? filterConfig.itemCount : FILTER_DEFAULT_VALUES.limit,
      loadingMore: false
    }

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeSortBy = this.onChangeSortBy.bind(this);
    this.onChangeNextCalibration = this.onChangeNextCalibration.bind(this);
    this.changeDateRange = this.changeDateRange.bind(this);
    this.onSetDatePicker = this.onSetDatePicker.bind(this);
    this.onClearDatePicker = this.onClearDatePicker.bind(this);
    this.onClickBody = this.onClickBody.bind(this);
    this.onKeydownBody = this.onKeydownBody.bind(this);
    this.onCompanySelectChange = this.onCompanySelectChange.bind(this);
    this.onAddressSelectChange = this.onAddressSelectChange.bind(this);
    this.handleAddressRequest = this.handleAddressRequest.bind(this);
    this.exportExcel = this.exportExcel.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.onClickLoadMore = this.onClickLoadMore.bind(this);
    this.getDeviceFilterParams = this.getDeviceFilterParams.bind(this);
  }

  componentDidMount(): void {
    // load results
    this.handleAddressRequest(true, true);

    // load company filter options (and address options depending on selected companies)
    this.setFilterCompanyOptions();

    document.body.addEventListener('click', this.onClickBody, {passive: true});
    document.body.addEventListener('keydown', this.onKeydownBody, {passive: true});
  }

  componentWillUnmount(): void {
    document.body.removeEventListener('click', this.onClickBody);
    document.body.removeEventListener('keydown', this.onKeydownBody);
  }

  setFilterCompanyOptions(updateAddressOptions: boolean = true) {
    this.setState({
      filterCompanyLoading: true
    }, () => {
      let params = FilterService.getSortParams({field: 'name', direction: SORT_DIRECTION_ASC});
      this.context.getCompanyRelatedCompanies(this.props.user?.company?.id, params)
        .then((companiesObj: { count: number, data: company[] }) => {
          let companyOptions: OptionType[] = [];
          if (companiesObj.data && companiesObj.data.length > 0) {
            companiesObj.data.forEach(company => {
              companyOptions.push({
                value: company.id,
                label: company.name,
                data: {
                  companyId: company.companyId,
                  internalId: company.internalId
                }
              });
            });
          }
          this.setState({
            filterCompanyOptions: companyOptions,
            filterCompanyLoading: false
          }, () => {
            if (updateAddressOptions)
              this.setFilterAddressOptions()
          });
        })
        .catch((response: AxiosResponse) => {
          this.setState({
            filterCompanyLoading: false
          });
        })
      ;
    });
  }

  setFilterAddressOptions() {
    const params = FilterService.getSortParams(
      {field: 'name', direction: SORT_DIRECTION_ASC}
    )
    if (this.state.filterCompanySelectedValues) {
      let selectedCompanies: number[] = [];
      this.state.filterCompanySelectedValues.forEach(val => {
        // @ts-ignore
        selectedCompanies.push(val.value);
      });
      if (selectedCompanies.length)
        Object.assign(params, {'filter[company.id][in]': selectedCompanies.join(',')});
    }

    this.setState({}, () => {
      this.setState({
        filterAddressLoading: true
      }, () => {
        this.context.getDeviceAddressesFromCompany(this.props.user?.company?.id, params)
          .then((addressesObj: { count: number, data: address[] }) => {
            let addressOptions: OptionType[] = [];
            if (addressesObj.data && addressesObj.data.length > 0) {
              addressesObj.data.forEach(address => {
                addressOptions.push({
                  value: address.id,
                  label: AddressService.getAddressFullNameString(address),
                  data: {
                    addressId: address.addressId
                  }
                });
              });
            }
            this.setState({
              filterAddressOptions: addressOptions,
              filterAddressLoading: false
            })
          })
          .catch(() => {
            this.setState({
              filterAddressLoading: false
            });
          })
        ;
      });
    });
  }

  onChangeInput(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    let inputValue = e.target.value;
    this.setState({
      filterSearch: inputValue,
    }, () => {
      if (this.state.typingSearchTimeout)
        clearTimeout(this.state.typingSearchTimeout);
      this.setState({
        typingSearchTimeout: setTimeout(() => {
          this.updateFilter();
        }, TYPING_DELAY)
      });
    });
  }

  onChangeSortBy(value: ValueType<OptionType, false> | null): void {
    this.setState({
      sortBy: value
    }, () => {
      this.updateFilter(false);
    });
  }

  onChangeNextCalibration(value: ValueType<OptionType, false> | null): void {
    this.setState({
      filterNextCalibration: value
    }, this.updateFilter);
  }

  onClickDatePicker(e: React.MouseEvent) {
    e.stopPropagation();
    this.setState({
      showBuyDatePicker: true
    });
  }

  onClickCalendar(e: React.MouseEvent) {
    e.stopPropagation();
  }

  onSetDatePicker() {
    if (this.state.showBuyDatePicker) {
      this.setState({
        showBuyDatePicker: false,
        showBuyDateLabel: true
      }, this.updateFilter);
    }
  }

  onClearDatePicker() {
    this.setState({
      showBuyDatePicker: false,
      showBuyDateLabel: false,
      filterBuyDateStart: FILTER_DEFAULT_VALUES.buyDateStart,
      filterBuyDateEnd: FILTER_DEFAULT_VALUES.buyDateEnd
    }, this.updateFilter);
  }

  changeDateRange(startDate: Date, endDate: Date): void {
    this.setState({
      filterBuyDateStart: startDate,
      filterBuyDateEnd: endDate
    });
  }

  onClickBody(e: MouseEvent): void {
    // @ts-ignore
    if (!e.target.closest('.calendar__wrapper') && !e.target.closest('.rdrCalendarWrapper')) {
      this.setState({
        showBuyDatePicker: false
      });
    }
  }

  onKeydownBody(e: KeyboardEvent): void {
    if (e.key === 'Escape') {
      this.setState({
        showBuyDatePicker: false
      });
    }
  }

  onCompanySelectChange(value: ValueType<OptionType, true>): void {
    let selectedValues: OptionType[] = [];
    value.forEach(val => {
      // @ts-ignore
      selectedValues.push({label: val.label, value: val.value});
    });

    this.setState({
      filterCompanySelectedValues: selectedValues
    }, () => {
      this.updateFilter();
      this.setFilterAddressOptions();
    });
  }

  onAddressSelectChange(value: ValueType<OptionType, true>): void {
    let selectedValues: OptionType[] = [];
    value.forEach(val => {
      selectedValues.push({label: val.label, value: val.value});
    });

    this.setState({
      filterAddressSelectedValues: selectedValues
    }, () => {
      this.updateFilter();
    });
  }

  getFilterSearch(): string {
    let filterSearch = this.state.filterSearch ? this.state.filterSearch.trim() : FILTER_DEFAULT_VALUES.search;
    if (filterSearch !== FILTER_DEFAULT_VALUES.search)
      return filterSearch;
    return FILTER_DEFAULT_VALUES.search;
  }

  getNamedFilterParamConfig(field: namedFilterFieldDeviceAddresses): namedFilterParamConfig {
    let value = '';
    switch (field) {
      case 'search':
        value = this.getFilterSearch();
        break;
      case 'company':
        value = FilterService.getInValueFromSelectValues(this.state.filterCompanySelectedValues);
        break;
      case 'calibrationUntil':
        value = this.state.filterNextCalibration && this.state.filterNextCalibration.value !== FILTER_DEFAULT_VALUES.nextCalibration.value ? FilterService.formatDate(moment().add(this.state.filterNextCalibration.value, 'days').toDate()) : '';
        break;
      case 'from':
        value = this.state.filterBuyDateStart ? FilterService.formatDate(this.state.filterBuyDateStart) : '';
        break;
      case 'until':
        value = this.state.filterBuyDateEnd ? FilterService.formatDate(this.state.filterBuyDateEnd) : '';
        break;
    }
    return {
      name: NAMED_FILTER_DEVICE_ADDRESSES,
      field: field,
      value: value
    }
  }

  saveFilter() {
    let config: filterConfig = {
      search: this.getFilterSearch(),
      companies: this.state.filterCompanySelectedValues,
      addresses: this.state.filterAddressSelectedValues,
      buyDateStart: this.state.filterBuyDateStart ? FilterService.formatDate(this.state.filterBuyDateStart) : undefined,
      buyDateEnd: this.state.filterBuyDateEnd ? FilterService.formatDate(this.state.filterBuyDateEnd) : undefined,
      nextCalibration: this.state.filterNextCalibration,
      sortBy: this.state.sortBy,
      limit: this.state.limit,
      itemCount: this.state.itemCount
    };
    localStorage.setItem(FilterService.filterStoreIds.DeviceAddressOverview, JSON.stringify(config));
  }

  updateFilter(request: boolean = true) {
    this.saveFilter();
    this.setState({
      addresses: request ? [] : this.state.addresses,
      offset: 0,
      itemCount: this.state.limit,
      filterChangeIteration: this.state.filterChangeIteration + 1
    });
    if (request)
      this.handleAddressRequest();
  }

  getSavedFilterConfig() {
    let config = localStorage.getItem(FilterService.filterStoreIds.DeviceAddressOverview);
    if (config)
      return config ? JSON.parse(config) : '';
    return null;
  }

  handleAddressRequest(loadAll: boolean = true, isInitialRequest: boolean = false) {
    if (!isInitialRequest)
      DeviceService.deleteCurrentDeviceConfig();

    let params: stringKeyObject = {
      limit: this.state.limit,
      offset: this.state.offset,
      ...FilterService.getFilterParams(
        this.getNamedFilterParamConfig('search'),
        this.getNamedFilterParamConfig('company'),
        this.getNamedFilterParamConfig('calibrationUntil'),
        this.getNamedFilterParamConfig('from'),
        this.getNamedFilterParamConfig('until'),
        {
          field: 'id',
          operator: 'in',
          value: FilterService.getInValueFromSelectValues(this.state.filterAddressSelectedValues)
        }
      ),
      ...FilterService.getSortParams(
        {field: 'address_company.companyId', direction: SORT_DIRECTION_ASC},
        {field: 'name', direction: SORT_DIRECTION_ASC}
      )
    };

    this.setState({
      resultsLoading: loadAll,
      loadingMore: true
    }, () => {
      this.context.getDeviceAddressesFromCompany(this.props.user?.company?.id, params)
        .then((addressesObj: { count: number, data: address[] }) => {
          this.setState({
            addresses: this.state.addresses ? this.state.addresses.concat(addressesObj.data) : addressesObj.data,
            itemsMax: addressesObj.count,
            loaded: true,
            resultsLoading: false,
            loadingMore: false
          })
        })
        .catch(() => {
          this.setState({
            resultsLoading: false,
            loaded: true,
            loadingMore: false
          });
        })
      ;
    });
  }

  removeCompanySelectValue(value: number) {
    let selectedValue = [...this.state.filterCompanySelectedValues];
    let index = selectedValue.findIndex(item => item.value === value);
    if (index !== -1) {
      selectedValue.splice(index, 1);
      this.setState({
        filterCompanySelectedValues: selectedValue
      }, () => {
        this.updateFilter();
        this.setFilterAddressOptions();
      });
    }
  }

  removeAddressSelectValue(value: string | number) {
    let selectedValue = [...this.state.filterAddressSelectedValues];
    let index = selectedValue.findIndex(item => item.value === value);
    if (index !== -1) {
      selectedValue.splice(index, 1);
      this.setState({
        filterAddressSelectedValues: selectedValue
      }, this.updateFilter);
    }
  }

  getDeviceFilterParams(): stringKeyObject {
    let params = FilterService.getFilterParams(
      this.getNamedFilterParamConfig('search'),
      this.getNamedFilterParamConfig('calibrationUntil'),
      this.getNamedFilterParamConfig('from'),
      this.getNamedFilterParamConfig('until')
    );

    if (this.state.sortBy && this.state.sortBy.value !== '') {
      Object.assign(params, FilterService.getSortParams({
        field: this.state.sortBy.value,
        direction: this.getSortByDirection()
      }));
    }

    return params;
  }

  exportExcel() {
    this.setState({excelLoading: true}, () => {
      let params = FilterService.getFilterParams(
        this.getNamedFilterParamConfig('search'),
        this.getNamedFilterParamConfig('company'),
        this.getNamedFilterParamConfig('calibrationUntil'),
        this.getNamedFilterParamConfig('from'),
        this.getNamedFilterParamConfig('until'),
        {
          field: 'address',
          operator: 'in',
          value: FilterService.getInValueFromSelectValues(this.state.filterAddressSelectedValues)
        }
      );

      if (this.state.sortBy && this.state.sortBy.value !== '') {
        Object.assign(params, FilterService.getSortParams({
          field: this.state.sortBy.value,
          direction: this.getSortByDirection()
        }));
      }

      this.context.exportDevicesExcel(params)
        .then((result: Blob) => {
          const file = new Blob([result], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          window.open(URL.createObjectURL(file));
        })
        .finally(() => {
          this.setState({excelLoading: false});
        })
      ;
    })
  }

  getSortByDirection(): sortDirection {
    if (this.state.sortBy && SORT_BY_DIRECTIONS.hasOwnProperty(this.state.sortBy.value))
      return SORT_BY_DIRECTIONS[this.state.sortBy.value];
    return SORT_DIRECTION_ASC;
  }

  resetFilter(event: React.MouseEvent) {
    event.preventDefault();
    this.setState({
      filterSearch: FILTER_DEFAULT_VALUES.search,
      filterCompanySelectedValues: FILTER_DEFAULT_VALUES.companies,
      filterAddressSelectedValues: FILTER_DEFAULT_VALUES.addresses,
      filterNextCalibration: FILTER_DEFAULT_VALUES.nextCalibration,
      filterBuyDateStart: FILTER_DEFAULT_VALUES.buyDateStart,
      filterBuyDateEnd: FILTER_DEFAULT_VALUES.buyDateEnd,
      showBuyDateLabel: false
    }, () => {
      this.updateFilter();
      this.setFilterCompanyOptions();
    });
  }

  onClickLoadMore() {
    this.setState({
      loadingMore: true,
      offset: this.state.itemCount,
      itemCount: this.state.itemCount + this.state.limit,
    }, () => {
      this.saveFilter();
      this.handleAddressRequest(false);
    });
  }

  render() {
    const datePickerClasses = {
      'form-control': true,
      'active': (this.state.filterBuyDateStart || this.state.filterBuyDateEnd),
    };

    const companyProps = {
      cId: 'Company ID',
      iId: 'Internal Company ID',
      address: 'Company address'
    }

    return (
      <div className="container product-address-overview">
        <h1 className="pagetitle">{PageService.pagesConfigurations[PAGE_PRODUCT_ADRESSES].title}</h1>
        <BackButton to={PageService.pagesConfigurations[PAGE_HOME].path}>
          Back to {PageService.pagesConfigurations[PAGE_HOME].title}
        </BackButton>
        {this.state.loaded
          ? <>
            <div className="contentbox">
              <div className="row small-gutter">
                <div className="col-12 col-sm-6 col-md-4">
                  <InputText
                    id="deviceAddressSearch"
                    className="search-devices"
                    placeholder="Search devices"
                    mode="highlighted"
                    value={this.state.filterSearch}
                    onChange={this.onChangeInput}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <Select
                    id="deviceAddressFilterCompany"
                    placeholder="Search"
                    options={this.state.filterCompanyOptions}
                    menuIsOpen={true}
                    isMulti={true}
                    value={this.state.filterCompanySelectedValues}
                    selectLabel="Select company"
                    loading={this.state.filterCompanyLoading}
                    // @ts-ignore
                    onChange={this.onCompanySelectChange}
                    filterOption={FilterService.filterOptionCompany}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <Select
                    id="deviceAddressFilterAddress"
                    placeholder="Search"
                    options={this.state.filterAddressOptions}
                    menuIsOpen={true}
                    isMulti={true}
                    value={this.state.filterAddressSelectedValues}
                    selectLabel="Select address"
                    loading={this.state.filterAddressLoading}
                    // @ts-ignore
                    onChange={this.onAddressSelectChange}
                    filterOption={FilterService.filterOptionAddress}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <Select
                    placeholder="Search"
                    menuIsOpen={true}
                    options={SORT_BY_OPTIONS}
                    // @ts-ignore
                    onChange={this.onChangeSortBy}
                    value={this.state.sortBy}
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <div className="form-group">
                    <div className="daterange__picker__wrapper">
                      <div
                        id="deviceAddressBuyDate"
                        className={classNames(datePickerClasses)}
                        onClick={this.onClickDatePicker.bind(this)}
                      >
                        {this.state.showBuyDateLabel
                          ? <>
                            <Moment format="YYYY-MM-DD">{this.state.filterBuyDateStart}</Moment>
                            <span> - </span>
                            <Moment format="YYYY-MM-DD">{this.state.filterBuyDateEnd}</Moment>
                          </>
                          : <>Buy date</>
                        }
                      </div>
                      <div className="calendar__wrapper" onClick={this.onClickCalendar.bind(this)}>
                        <DaterangePicker
                          showPopup={this.state.showBuyDatePicker}
                          startDate={this.state.filterBuyDateStart}
                          endDate={this.state.filterBuyDateEnd}
                          changeDateRange={this.changeDateRange}
                          onClearDatePicker={this.onClearDatePicker}
                          onSetDatePicker={this.onSetDatePicker}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                  <Select
                    placeholder="Search"
                    menuIsOpen={true}
                    options={CALIBRATION_OPTIONS}
                    // @ts-ignore
                    onChange={this.onChangeNextCalibration}
                    value={this.state.filterNextCalibration}
                  />
                </div>
              </div>
              {(this.state.filterSearch !== FILTER_DEFAULT_VALUES.search
                  || this.state.filterCompanySelectedValues.length > 0
                  || this.state.filterAddressSelectedValues.length > 0
                  || (this.state.filterNextCalibration && this.state.filterNextCalibration.value !== FILTER_DEFAULT_VALUES.nextCalibration.value)
                  || (this.state.filterBuyDateStart && this.state.filterBuyDateEnd)
                ) &&
                <div className="row small-gutter">
                  <div className="col-12 col-md-10">
                    <div className="selectedValues__container">
                      {this.state.filterSearch !== FILTER_DEFAULT_VALUES.search &&
                        <div
                          onClick={() => {
                            this.setState({filterSearch: FILTER_DEFAULT_VALUES.search}, this.updateFilter);
                          }}
                          className="selectedValue"
                          key="filter-search-remove"
                        >
                          Search device: {this.state.filterSearch}
                        </div>
                      }
                      {this.state.filterCompanySelectedValues.map((selected, index) => {
                        return (
                          <div
                            // @ts-ignore
                            onClick={() => this.removeCompanySelectValue(selected.value)}
                            className="selectedValue"
                            key={index}
                          >
                            Company: {selected.label}
                          </div>
                        )
                      })}
                      {this.state.filterAddressSelectedValues.map((selected, index) => {
                        return (
                          <div
                            onClick={() => this.removeAddressSelectValue(selected.value)}
                            className="selectedValue"
                            key={index}
                          >
                            Address: {selected.label}
                          </div>
                        )
                      })}
                      {(this.state.filterBuyDateStart && this.state.filterBuyDateEnd) &&
                        <div
                          onClick={() => {
                            this.setState({
                              filterBuyDateStart: undefined,
                              filterBuyDateEnd: undefined,
                              showBuyDateLabel: false
                            }, this.updateFilter);
                          }}
                          className="selectedValue"
                          key="filter-purchaseDate-remove"
                        >
                          Buy date: {this.state.filterBuyDateStart.toLocaleDateString()} - {this.state.filterBuyDateEnd.toLocaleDateString()}
                        </div>
                      }
                      {(this.state.filterNextCalibration && this.state.filterNextCalibration.value !== FILTER_DEFAULT_VALUES.nextCalibration.value) &&
                        <div
                          onClick={() => {
                            this.setState({filterNextCalibration: FILTER_DEFAULT_VALUES.nextCalibration}, this.updateFilter);
                          }}
                          className="selectedValue"
                          key="filter-calibration-remove"
                        >
                          {this.state.filterNextCalibration.label}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-12 col-md-2 reset-filter-col">
                    <a href={window.location.href} onClick={this.resetFilter} className="reset-filter">Reset filter</a>
                  </div>
                </div>
              }
            </div>
            <div className="contentbox">
              {(this.state.addresses && this.state.addresses.length > 0) &&
                <div className="btn-export-devices__wrapper">
                  <Button
                    onClick={this.exportExcel}
                    disabled={this.state.excelLoading}
                    className="btn-export-devices"
                  >
                    export to Excel file
                  </Button>
                </div>
              }
              <div className="propertylist__header">
                <div className="company__propertylist">
                  <div className="list__col list__col--cid">{companyProps.cId}</div>
                  <div className="list__col list__col--iid">{companyProps.iId}</div>
                  <div className="list__col list__col--address">{companyProps.address}</div>
                </div>
              </div>

              {this.state.resultsLoading
                ? <Loader mode="overview"/>
                : <>
                  {this.state.addresses.map((address, index) => {
                    return (
                      <DeviceAddressEntry
                        address={address}
                        key={index}
                        getFilterParams={this.getDeviceFilterParams}
                        pageConfig={DeviceAddressOverview.pageConfig}
                        filterChangeIteration={this.state.filterChangeIteration}
                      />
                    );
                  })}

                  {this.state.addresses.length > 0
                    ? this.state.itemCount < this.state.itemsMax &&
                    <div className="loading__indicator__wrapper">
                      <div onClick={this.onClickLoadMore}>
                        <LoadingIndicator loading={this.state.loadingMore} text="Show more"/>
                      </div>
                    </div>
                    : <h3 className="text-center mt-5">No addresses/devices found.</h3>
                  }
                </>
              }
            </div>
          </>
          : <Loader mode="overview"/>
        }
      </div>
    )
  }
}

DeviceAddressOverview.contextType = ApiContext;

export default DeviceAddressOverview;